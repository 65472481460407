/*
 Search bar
 */
.catalog-search {
  @extend .grid--l;
  @extend .advanced-search;
  //max-width: 640px;
  display: flex;

  > .icon {
    @extend .adv-search__bl;
    float: none;

    > * {
      @extend .fullAlign;
      float: none;
    }
  }

  > .input {
    @extend .adv-search__input;
    float: none;
    width: initial;
    flex: 1;

    md-autocomplete-wrap {
      border: none;
      height: 100%;
      flex: 1;
    }
    md-autocomplete {
      height: 100%;
    }
  }
  md-autocomplete input {
    font-size: 16px;
    padding-right: 35px;
  }

  > .button {
    @extend .adv-search__submit;
    float: none;
    position: static;
    margin-bottom: 0;
  }
}

/*
 Override md-autocomplete min-height calc
 */
.catalog {
  .md-autocomplete-suggestions-container {
    // Force minimum of 3 items height
    min-height: calc(74px * 3);
  }
}

/*
 Custom md-item template
 */
.catalog-search--autocomplete {
  flex-direction: column;

  > li {
    height: auto;
    width: 100%;
    padding: $catalog-gutter;
    white-space: normal;
    border: none;
    display: flex;

    &:nth-child(even) {
      background-color: $catalog-color-light-gray;
    }
    &:hover {
      .summary-list_image {
        border-color: $catalog-color-dark-gray;
      }
      .catalog-material_name {
        color: $red;
      }
    }

    * {
      font-size: 11px;
      font-weight: normal;
      line-height: 1.4;
      margin-bottom: 0;
    }
    span {
      padding: 0;
    }

    > .item {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }

    .catalog-info {
      width: 100%;
      max-width: calc(100% - 85px - #{$catalog-gutter-double});
    }
    .summary-list_image {
      width: 85px;
      height: 45px;
      border: 3px solid transparent;
      transition: border 0.3s ease-out;
    }
    .catalog-card_tags {
      min-height: initial;
      margin-bottom: $catalog-gutter-half;
    }
    .catalog-card_tag,
    .catalog-card_tag--new {
      width: auto;
      line-height: 1.6;
      padding-left: $catalog-gutter-half;
      padding-right: $catalog-gutter-half;
    }
    .catalog-material_name {
      @extend .truncate;

      margin-bottom: 0;
      font-weight: bold;
      transition: color 0.3s ease-out;
    }
  }
}
