.awsAjaxSubmit {
  position: relative;
  width: 100%;
  float: left;
  &__text {
    width: 90%;
    float: left;
    border-right: none;
    border-radius: 3px 0 0 3px;
    height: 38px;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 20px;
    padding-right: 10%;
  }

  &__progress {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__progress__value {
    width: 40px;
    font-size: 14px;
    line-height: 14px;
    margin-left: 4px;
    text-align: center;
  }

  &__progress__loaded {
    text-align: right;
    font-size: 12px;
  }
}

.koInputFile {
  width: 100%;
  height: 38px;
  display: inline-block;
  position: relative;

  &__file {
    display: none;
  }

  &__text {
    width: 98%;
    float: left;
    border-right: none;
    border-radius: 3px 0 0 3px;
    height: 38px;
  }

  &__button {
    width: 86px;
    margin: 0;
    background-color: #f0f0f0 !important; /* FIX EDGE */
    border-radius: 0 3px 3px 0;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;

    &:hover {
      background: #dedede;
    }

    &[disabled="disabled"] {
      background-color: $disabled !important;
    }
  }

  .md-button.md-koTheme-theme:not([disabled]):hover {
    background: #dedede;
  }

}

/* Input File with Download button*/
.form-input-file {
  &__with-download {

    display: block;
    overflow: hidden;

    ko-form-input-file {
      float: left;
      width: calc(100% - 47px);
    }

    .button--icon {
      float: right;
      margin-top: 20px;
    }

    &.no-file {
      ko-form-input-file {
        width: 100%;
        float: none;
      }

      .button--icon {
        display: none;
      }
    }

  }
}
