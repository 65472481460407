.notice {
  margin: 0 0 15px 0;
  border: 1px solid;
  font-size: 11px;
  padding: 8px;
  border-radius: 3px;
  font-weight: bold;
  border-color: $red;
  color: $red;

  &.error {
    border-color: $red;
    color: $red;
  }

  &.success {
    border-color: $success_color;
    color: $success_color;
  }

  i {
    font-size: 18px;
    margin: 0 10px;
  }
}
