$green-chosen: #d5ffa6;
$green-lowest: #f7ffed;
$green-lowest-dark: #95da48;
$gray-dark: #353535;
$gray-light: #6f6f6f;
$red: #f41f19;

.cascade {
  border-collapse: inherit;
  border-spacing: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: $gray-light;
  overflow: hidden;

  thead,
  tbody {
    display: block;
    position: relative;
    width: 100%;
  }
}

.cascade--scrollable {
  .cascade-column {
    width: 200px;
  }
  .cascade-column--range {
    width: calc(100% - 400px);

    &.extended {
      width: calc(100% - 200px);

    }
  }
  .cascade-column:nth-child(3):after {
    content: '';
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transform: translateX(-100%);

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
  }
}

.cascade-inner {
  @extend .cascade;
  width: 100%;

  tr,
  tr th {
    //display: inline-block;
    display: flex;
    justify-content: center;
    width: 100%;

    &[align="left"] {
      justify-content: flex-start;
    }

    &:last-child {
      min-width: 200px;
      overflow: hidden;
    }
  }
  tr {
    border-bottom: 1px solid #6f6f6f;
  }
  tr th {
    height: 40px;
  }
}

.cascade-column {
  float: left;
  width: 20%;
  vertical-align: top;
  position: relative;
  right: initial;
}

.cascade-column--range {
  @extend .cascade-column;
  width: 60%;
  overflow-x: auto;
  display: flex;
  flex: 1;

  &.extended {
    width: 80%;
  }

  > td {
    display: flex;
    //width: 100%;
    flex: 1;
  }
}

.cascade-column--side {
  @extend .cascade-column;

  > td {
    display: block;
  }
  .cascade_row {
    display: table;
  }

  &:nth-child(3) {
    .cascade_cell {
      text-align: center;
    }
  }
}

.cascade_cell {
  border: 0;
  margin: 0;
  display: table-cell;
  font-size: 11px;
  height: 80px;

  &:nth-child(1) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.cascade_cell--range {
  @extend .cascade_cell;
  display: inline-block;

  &:nth-child(1) {
    padding-left: 0;
    padding-right: 0;
  }
}

.cascade_cell--header {
  @extend .cascade_cell;

  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.cascade_range {
  width: 100%;
  height: 100%;
  padding: 20px 5px;
  cursor: pointer;
  border: 3px dotted transparent;
  transition: all 0.3s ease-out;

  &:hover {
    border-color: $green-lowest-dark;
  }
}

.cascade_range--view {
  cursor: default;
  pointer-events: none;
}

.cascade_range--lowest {
  background-color: $green-lowest;
  border-color: $green-lowest-dark;
}

.cascade_range--chosen {
  background-color: $green-chosen;
  border-color: $green-chosen;
}

.highlight {
  font-weight: bold;
}

.supplier_name {
  font-weight: bold;
  font-size: 13px;
  text-overflow: ellipsis;
  width: calc(100% - 0.5em);
  overflow: hidden;
  height: 1em;
  white-space: nowrap;
  max-width: 200px;
}

.supplier_value {
  font-weight: bold;
  font-size: 14px;
}

.supplier_production-time--gtlead {
  color: $red;
}

.legend {
  list-style: none;
  font-size: 12px;
  color: $gray-light;
  // position: absolute;
  // right: 20px;
  // margin-top: 7px;

  > li {
    display: inline-block;
    position: relative;
    margin-left: 61px;
    line-height: 25px;

    &:before {
      content: '';
      position: absolute;
      width: 21px;
      height: 21px;
      border-width: 2px;
      border-style: dotted;
      transform: translateX(-30px);
    }
  }
}

.legend--lowest {
  &:before {
    border-color: $green-lowest-dark;
  }
}

.legend--replan {
  &:before {
    border-color: $replan-color;
    background-color: $replan-color;
  }
}

.legend--chosen {
  &:before {
    background-color: $green-chosen;
    border-color: $green-chosen;
  }
}
