.range {
  &__grid {
    width: 100%;
    border: 0;
    padding: 0;
  }

  &__cell {
    padding: 0 10px 15px;
    padding-bottom: 15px;
    text-align: center;
    white-space: nowrap;

    &--name {
      @extend .range__cell;
      text-align: left;
      padding-left: 0;
    }

    &--field {
      @extend .range__cell;
      width: 100px;
    }

    &--actions {
      @extend .range__cell;
      padding-right: 0;
      width: 100px;
    }
  }

  &__input {
    @extend .form-item__input;

    &[disabled=disabled] {
      cursor: not-allowed;
    }
  }

  &__label {
    @extend .font-small;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    display: block;

    &--from,
    &--to {
      @extend .range__label;
      text-align: right;
      padding-right: 10px;
    }
  }
}

.tableGrid--striped .border--highlight {
  border: solid #aaa 2px;

  & input, td {
    font-weight: bold;
  }
}
