.notification {

  &--disabled {
    color: #747474;
  }
  &--label-round {
    border-radius: 6px;
    height: 12px;
    width: 12px;
  }
}