.messages {

  &--click {
    cursor: pointer;
  }

  &.md--center {
    margin: 85px auto 20px auto;
    float: none;
    width: 80%;
  }

  #datatable .material-icons {
    font-size: 20px;
    &.both {
      font-size: 14px;
    }
  }
  md-list-item.md--custom {
    padding: 0;
  }
  .md-list-item-text {
    .typeTitle {
      font-size: 14px;
      display: block;
      text-transform: uppercase;
      color: #3b3b3b;
      font-weight: bold;
      white-space: normal;
    }
    .text {
      margin-top: 2px;
      color: #848484;
      font-size: 12px;
    }
  }
  .msg-info {
    white-space: normal;
    .text {
      @extend .main-col;
      margin-top: 2px;
      color: #848484;
      font-size: 12px;
      display: block;
    }
  }

  .messagesTable thead th {
      padding: 0 8px;
  }
  .recipients-col {
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    position: absolute;
    margin-left: -180px;
    width: 240px;
    z-index: 401;
    white-space: normal;
    margin-bottom: 20px;

    p {
      text-align: left;
      font-size: 12px;
    }

    .color {
      color: #ff0000;
    }

    .tooltip > div {
      display: block;
      padding:8px 15px;
      border-bottom:1px solid #cacaca;
      text-align: left;
      p {
        display: inline;
        white-space: normal;
        line-height: normal;
      }
      span {
        color: #9c9c9c;
      }
      .email {
        display: inline-block;
      }
    }
  }
  .md-koTheme-theme .md-datepicker-input-container {
    width: 78%;
  }
}

@media only screen and (min-width: 480px ) and (max-width: 760px) {
  .messages {
    &.md--center {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 760px ) and (max-width: 1050px) {
  .messages {
    &.md--center {
      width: 100%;
    }
  }
}
