.adv-search {
  height: 38px;
  width: 100%;
  position: relative;
  z-index: 500;
  //top: 50%;
  //transform: translateY(-50%);

  &__form {
    position: absolute;
    top: 52px;
    left: 0;
    right: 0;
    padding: 10px;
    background: #fff;
    z-index: 500;
    overflow: auto;
    max-height: calc(100vh - 118px);
    width: 100%;

    .actions {
      text-align: right;
    }
  }

  &.open {
    .adv-search__content {
      position: absolute;
      top: 38px;
      display: block;
    }
  }
  &__content {
    display: none;
    width: 100%;
    height: 100px;
    background-color: white;
    border-radius: 0 0 5px 5px;
    z-index: -1;
  }
  &__bl {
    width: 38px;
    height: 100%;
    background-color: #FFF;
    border: 1px solid #d2d2d2;
    border-right: none;
    float: left;
    border-radius: 3px 0 0 3px;
    text-align: center;

    .material-icons {
      color: #cccccc;
      font-size: 18px;
    }
  }

  &__input {
    display: inline-block;
    outline: none;
    height: 100%;
    width: 80%;
    width: calc(100% - 28px - 38px);
    background-color: #fff;
    border: 1px solid #d2d2d2;
    border-right: none;
    border-left: none;
    float: left;
    padding: 0;
  }

  &__arrow {
    width: 28px;
    height: 100%;
    background-color: #fff;
    border: 1px solid #d2d2d2;
    border-left: none;
    //float: right;
    position: absolute;
    top: 0;
    right: 52px;
  }

  &__submit {
    color: #fff;
    width: 52px;
    height: 100%;
    background-color: #ff1212;
    border: 1px solid #d25353;
    border-left: none;
    //float: left;
    border-radius: 0 3px 3px 0;
    position: absolute;
    top: 0;
    right: 0;
    .material-icons {
      display: block;
    }
  }
  &__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #FFF;
    opacity: 0;
    z-index: 400;
  }
}

@media only screen and (min-width: $mobileFirst_break) {
  .adv-search {
    height: 38px;
    width: 100%;
    position: relative;
    z-index: 500;
    //top: 50%;
    //transform: translateY(-50%);

    &__form {
      top: 100%;
      left: 50%;
      padding: 25px;
      max-height: 50vh;
      width: 100%;
      min-width: 500px;
      transform: translateX(-50%);
    }
  }
}
