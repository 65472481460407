.text {
  font-weight: normal;

  &--small {
    @extend .title;
    font-size: 11px;
    line-height: 16px;
  }

  &__link {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 20px;
    @extend .linkInGrid;
  }

  &--left {
    float: left;
  }

  &--right {
    float: right;
  }

}
