/* Star size */
.ngrateit-star{
  background-size: 16px;
}

ko-rate-it {
  display: inline-block;
  min-width: 80px;
}
.ko-rateit--big {
  min-height: 40px;

  .ngrateit-star{
    background-size: 32px;
    margin-right: 5px;
  }
}

/* Background */
.ko-rateit .ngrateit-bg-star{
  background-image: url("/svg/star-bg.svg");
}
/* Selected state */
.ko-rateit .ngrateit-selected {
  background-image: url("/svg/star-rated.svg");
}

.ko-rateit.ngrateit:not(.ngrateit-readonly) {
  /* Cancelation of hover styling */
  .ngrateit-rating span:hover ~ span {
    background-image: url("/svg/star-bg.svg");
  }
  /* Hover styling */
  .ngrateit-rating:hover span {
    background-image: url("/svg/star-hover.svg");
  }
}
