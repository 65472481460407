.material {
  .headerContent {
    .iconRounded {
      float: left;
      margin-right: 8px;
    }
  }
  &__thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;

    ko-thumbnail img {
      height: 40px;
    }
  }

  .form-item-status.col--50:first-child {
    padding-left: 15px;
    padding-right: 0;
  }

  .tableGrid {
    margin-top: 0;
  }

  .uploadFiles {
    float: left;
    width: 100%;
    margin-top: -20px;
  }

  .ngdialog.ngdialog-theme-default.tabs .ngdialog-content .md-tab[disabled],
  .ngdialog.ngdialog-theme-default.tabs .ngdialog-content .md-tab.md-disabled {
    opacity: 0.5;
  }

  .lowRes-box {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
}

.material__upload-status {
  clear: both;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 2px;
  padding: 8px 16px;
  font-size: 11px;

  &--uploaded,
  &--saved,
  &--error {
    @extend .material__upload-status;
  }

  &--saved {
    color: #090;
  }

  &--error {
    color: #f00;
  }
}

.material__form-item-upload {
  border-bottom: 1px solid #ccc;
  padding: 16px 0 0;
  margin: 0;
  min-height: 110px;

  .form-item__label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .form-legend {
    display: none;
  }

  .notice.error {
    clear: both;
  }
}

.form-legend--material-upload {
  font-size: 10px;
  margin-top: 10px;
}