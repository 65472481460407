.ko-backdrop {
  &-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    z-index: 70;
    &__white {
      @extend .ko-backdrop-overlay;
      background: #FFF;
    }
  }
  &-content {
    z-index: 80;
    position: relative;
  }
}
