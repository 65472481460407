.c3m-kaarousel.home {
  background: #f2f2f2;
  &__main-full {
    &--top {
      background: #f7f7f7;
      z-index: 5;
      display: flex;
      position: fixed;
      min-height: 66px;
      height: auto;
      padding: 10px 0px;
      border-bottom: 1px solid #d2d2d2;
      width: 100%;

      .top {
        font-weight: bold;
        div {
          padding: 2px 0;
        }
      }
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    &--small-txt {
      color: #999;
      font-size: 14px;
    }
  }

  .main-medium {
    display: block;
    padding: 20px 0 0 0;
    width: 100%;
    max-width: 708px;
    margin: 85px auto 0 auto;

    .wrapper-box {
      display: block;
      position: relative;
      width: 100%;
      overflow: hidden;

      &.banner-section {
        min-height: 350px;
      }
      &.material-section {
        min-height: 310px;
      }
      &.calendar-section {
        min-height: 437px;
      }
      &.forum-section {
        min-height: 385px;
      }
      &.advertising-section {
        min-height: 330px;
      }
    }

    &--radius {
      border-radius: 7px;
      background: #fff;
    }

    &--top {
      margin-bottom: 35px;
    }

    &--middle {

      .calendar .kaarousel-nav {
        position: absolute;
        top: -36px;
        right: -8px;
      }

      .icon-avatar {
        background-color: rgba(0, 0, 0, 0.4);
        color: #FFF;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 8px;
        font-size: 18px;
        padding: 6px;
      }
      .md-chip {
        font-size: 12px;
        border-radius: 5px;
        height: auto;
        line-height: normal;
        padding: 3px 8px;
        margin-top: 0;
      }
      .md-chips {
        box-shadow: none;
        margin-bottom: 15px;
        padding: 0;
      }
      .layout-calendar {
        display: inline-block;
        max-width: 710px;
        width: 100%;
        height: 40px;
        .kaarousel-actions-wrapper {
          display: inline-block;
          padding: 0px 6px 0px 2px;
          width: 100%;
          max-width: 352px;
        }

        .kaarousel-nav {
          position: absolute;
        }
        kaarousel-pager {
          display: none;
        }
      }
      md-card.calendar {
        margin-top: 36px;
        margin-bottom: 30px;
        position: relative;
        margin-left: 8px;
        margin-right: 8px;
        li {
          padding: 8px 15px;
          cursor: pointer;
          label {
            font-size: 13px;
            cursor: pointer;
          }
          span {
            font-size: 11px;
            color: #aaa;
            padding-left: 10px;
            // display: none;

            &.activation-plan-name {
              padding: 0;
              text-transform: uppercase;
              color: rgba(0, 0, 0, 0.87);
            }
          }
        }
        li.selected {
          label {
            font-weight: bold;
          }
          span {
            display: inline;
          }
        }
        md-icon {
          font-size: 17px;
        }
        h3 {
          font-size: 14px;
          font-weight: normal;
          text-transform: uppercase;
          color: #444;
          letter-spacing: 3px;
          border-bottom: 1px solid #ddd;
          padding: 15px;
          margin-bottom: 10px;
          width: 95%;
          margin: 7px auto;
          md-icon {
            color: #aaa;
          }
        }
        .border-right {
          border-right: 1px solid #ddd;
          padding-bottom: 20px;
          min-height: 10px;
        }
        md-card-content {
          padding: 0 16px;
        }
      }
      .ko-calendar-month {
        max-width: 350px;
        width: 100%;
        font-size: 14px;
        letter-spacing: 3px;
        text-transform: uppercase;
        text-align: center;
        color: #444;

        thead, tbody {
          display: inline-table;
          width: 100%;
          max-width: 350px;
        }

        thead {
          th {
            font-weight: normal;
            border-bottom: 1px solid #ddd;
            padding: 20px;
          }
          td {
            color: red;
          }
        }
        td {
          padding: 3px;
          div {
            border-radius: 50%;
            padding: 12px 0px 10px 1px;
            transition: color 0.3s ease-out, background-color 1s ease-out;
          }
          div.marked {
            font-weight: bold;
            color: #FFF;
          }

        }
      }
    }
  }
}

.home-material-link {
  cursor: pointer;
}

.holderCalendar {
  display: inline-block;
  height: 346px;
}

.footer {
  background: #505050;
  min-height: 170px;
  width: 100%;
  bottom: 0px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  .stamps {
    width: 100%;
    max-width: 640px;
    border-bottom: 1px solid #666;
    padding: 15px 4px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;

    a {
      margin: 0 4px;
    }
  }

  .contacts {
    font-size: 12px;
    color: #fff;
    padding: 20px;
    span {
      padding: 2px;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
    a:hover {
      text-decoration: none;
    }

  }
  &--login {
    bottom: 0;
    min-height: 50px;
    position: absolute;
  }
}

@media only screen and (min-width: $mobileFirst_break) {
  .footer {
    .stamps {
      justify-content: center;
      a {
        margin: 0 14px;
      }
    }
  }
}

/* Responsive */
@include phone {
  .c3m-kaarousel.home {

    .main-medium {
      padding: 5px 0 0 0;

      &--middle {

        .kaarousel-title {
          font-size: 94%;
        }

        .ko-calendar-month td div {
          border-radius: 32px;
          padding: 10px 0px 10px 1px;
          width: 32px;
          height: 32px;
        }

        .ko-calendar-month thead th {
          padding: 10px;
        }

        .kaarousel-slide {
          h3 {
            font-size: 110%;
          }

          p {
            font-size: 90%;
          }
        }

        .kaarousel.banner_top .kaarousel-slider .kaarousel-slide {
          width: 100%;

          md-card-content {
            a {
              display: block;
            }
            img {
              height: 212px;
            }
          }

          md-card-actions {
            a.md-button {
              display: inline-table;
            }
          }
        }

        .calendar .kaarousel-nav {
          top: -15px;
        }

        .kaarousel-nav .kaarousel-prev {
          left: inherit;
          right: 45px;
        }
        .kaarousel-nav .kaarousel-next {
          right: 15px;
        }

        .bx-materials .kaarousel-slide {
          background: #f2f2f2;
          margin: 0 auto;
          display: inline-block;
          width: 100%;
          padding: 0 10px 0 10px;
          overflow: hidden;
          md-card.md-koTheme-theme {
            width: auto;
          }

        }

        .kaarousel-actions-wrapper {
          padding-top: 30px;
          margin-bottom: 10px;
        }
      }

    }
  }

  .sub-nav {
    transform: translateX(-100%);
  }
}

@include phone-landscape {
  .c3m-kaarousel.home {
    .main-medium {
      padding: 5px 0 0 0;

      &--middle {

        .calendar .kaarousel-nav {
          top: -15px;
          right: 5px;
        }
        .layout-calendar .kaarousel-actions-wrapper {
          margin-left: 12%;
        }
      }
    }
  }
}

@include smartphone {
  .c3m-kaarousel.home {
    .main-medium {
      padding: 5px 8px 0 0;

      &--middle {

        .ko-calendar-month thead th {
          padding: 20px;
        }

        .ko-calendar-month thead th {
          padding: 20px;
        }

        ko-calendar-month td div {
          border-radius: 35px;
          padding: 10px 0px 10px 1px;
          width: 35px;
          height: 35px;
        }

        .layout-calendar .kaarousel-actions-wrapper {
          margin-left: 0;
        }

        .calendar .kaarousel-nav {
          top: -36px;
        }

        .kaarousel-nav .kaarousel-prev {
          left: inherit;
          right: 40px;
        }
        .kaarousel-nav .kaarousel-next {
          right: 10px;
        }

        .bx-materials .kaarousel-slide {
          display: inline-flex;
          margin: 0 auto;
          width: 50%;
          md-card.md-koTheme-theme {
            width: 100%;
          }

        }
        .kaarousel-slide {

          .post {
            width: 97%;
          }

          .banner md-card-actions h3 {
            font-size: 90%;
          }
          .banner .shadow {
            box-shadow: none;
          }
          .button--outline {
            font-size: 80%;
            margin: 6px 4px;
          }
        }
        .kaarousel-actions-wrapper {
          padding-top: 30px;
          margin-bottom: 10px;
        }
      }

    }
  }
}

@include tablet {
  .c3m-kaarousel.home {
    .main-medium {
      padding: 5px 8px 0 0;

      &--middle {

        .ko-calendar-month thead th {
          padding: 20px;
        }

        ko-calendar-month td div {
          border-radius: 35px;
          padding: 10px 0px 10px 1px;
          width: 35px;
          height: 35px;
        }

        .layout-calendar .kaarousel-actions-wrapper {
          margin-left: 0;
        }

        .calendar .kaarousel-nav {
          top: -36px;
        }

        .kaarousel-nav .kaarousel-prev {
          left: inherit;
          right: 40px;
        }
        .kaarousel-nav .kaarousel-next {
          right: 10px;
        }

        .bx-materials .kaarousel-slide {
          display: inline-flex;
          margin: 0 auto;
          width: 50%;
          md-card.md-koTheme-theme {
            width: 100%;
          }

        }
        .kaarousel-slide {

          .post {
            width: 97%;
          }

          .banner md-card-actions h3 {
            font-size: 90%;
          }
          .banner .shadow {
            box-shadow: none;
          }
          .button--outline {
            font-size: 80%;
            margin: 6px 4px;
          }
        }
        .kaarousel-actions-wrapper {
          padding-top: 30px;
          margin-bottom: 10px;
        }
      }

    }
  }
}

@include desktop {
  .c3m-kaarousel.home {
    .main-medium {
      padding: 5px 0 0 0;

      &--middle {
        .ko-calendar-month td div {
          border-radius: 35px;
          padding: 10px 0px 10px 1px;
          width: 35px;
          height: 35px;
        }

        .bx-materials .kaarousel-slide {
          width: 236px;
          padding: 0;
        }

        .kaarousel-slide .post {
          width: 100%;
        }

        .calendar .kaarousel-nav {
          right: -8px;
        }


      }
    }
    md-card {
      margin: 6px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
    .banner__top {
      .kaarousel-nav {
        right: 10px;
        position: relative;
      }

      md-card {
        position: relative;
        left: -3px;
      }
    }

  }
}

/* phone <= 360 */
@media only screen and (max-width: 360px) {
  .home .main-medium--middle .kaarousel-slide .banner md-card-actions {
    edit-item-grid {
      display: none;
    }
  }
}

/* phone - smartphone - tablet <= 600 */
@media only screen and (max-width: 600px) {
  .home {
    .ellipsis {
      width: 230px;
    }

    .main-medium {
      padding: 5px 0 0 0;

      &--middle {
        .kaarousel-slide {

          .post {
            width: 94%;

            label {
              display: inline-block;
              max-width: 120px;
              width: 100%;
            }

          }

          .banner md-card-actions {
            height: 68px;
          }

          .banner md-card-actions h3 {
            font-size: 90%;
            padding: 10px 0 0 18px;
          }
          .banner .shadow {
            box-shadow: none;
          }
          .button--outline {
            font-size: 60%;
            margin: 6px 4px;
            font-style: normal;
            .material-icons {
              font-size: 22px;
            }
          }
          .button--update {
            margin: 6px 0px;
            font-size: 50%;
            width: 38px;
          }
        }
      }

    }
  }
}

/* Internet Explorer 10 (only) */
_:-ms-lang(x), .home {
  .main-medium--middle {
    .kaarousel .kaarousel-slider .kaarousel-slide {
      left: -5px;
      max-width: 712px;
    }

    .bx-materials .kaarousel-slide {
      width: 236px;
      padding: 0;
      img {
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        width: 98% \9
      ;
        display: inline-block;
      }
    }

  }
  .kaarousel .kaarousel-slider .kaarousel-slide .post {
    max-width: 706px;
    left: -4px;
    position: relative;
  }
  .kaarousel .kaarousel-slider .kaarousel-slide md-card-actions {
    display: inline-block;
  }
}
