.company {

  .md-tab {
      padding: 12px 23px;
  }

}

.companyNewForm,
.companyEditForm {
  position: relative;
}

.fileSAAContainer,
.fileSGPContainer{
  padding-bottom: 10px;
}

@media only screen and (max-width: $mobile_break) {
  .company {
    .SAAContent,
    .SGPContent {
      .col {
        @for $i from 0 through 20 {
          $perc: $i * 5;

          &--#{$perc} {
            width: #{$perc}#{"%"} !important;
          }
        }
      }
    }
  }
}
