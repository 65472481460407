%hidden {
  text-indent: -999px;
  font-size: 0;
  line-height: 0;
}

.iconRounded {
  @extend %hidden;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  background: none;
  vertical-align: middle;

  &--0 {
    background: #fd011c;
  }
  &--1 {
    background: #74cd21;
  }
  &--2 {
    background: #f1a01c;
  }
  &--3 {
    background: #ffff00;
  }
  &--4 {
    background: #4285F4
  }
  &--small {
    width: 12px;
    height: 12px;
  }
  &--middle {
    vertical-align: middle;
  }
}
