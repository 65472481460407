.ko-dynamic-properties {
  @extend .form-item-container;
  margin-bottom: 15px;
}

.ko-dynamic-properties_item {
  @extend .form-item;
  padding-bottom: 0;
  .form-item__label {
    display: inline-block;
    min-height: 20px;
  }
}

.ko-dynamic-properties_options {
  @extend .form-item-container;
  @extend .col--nogutter;
  padding-bottom: 0;

  md-checkbox {
    max-height: 60px;
  }
}

.ko-dynamic-properties_buttons {
  padding-top: $label_height;
}

ko-form-autocomplete {
  .ko-summarize {
    &.error {
      border-radius: 3px;
      overflow: hidden;

      .custom-chips.md-koTheme-theme .md-chips, input {
        border: 0;
      }
    }
  }
}

//----------
// Component: status-color
// 0. transparent
// 1. red
// 2. green
// 3. orange
// 4. gray
// obs: len is the last color number
//----------
//
$statusColorMap: (
  color0: transparent,
  color1: #ff2612,
  color2: #7ed321,
  color3: #f5a623,
  color4: #dedede,
  len: 4
);

.status-color {
  width: 100%;
  min-width: 60px;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  display: inline-block;

  &--0 { background-color: transparent; }

  // Auto map status-colors from 1-4
  @for $i from 1 through map-get($statusColorMap, len) {
    &--#{$i} {
      background-color: rgba(map-get($statusColorMap, color#{$i}), 0.05);
      border: 2px solid rgba(map-get($statusColorMap, color#{$i}), 0.75);
    }
  }
}
//----------
//
