%col {
  padding-left: 15px;
  padding-right: 15px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

// columns from 0 to 100 (increment of 5. ex: 0%, 5%, 10%, ..., 100%)
.col {
  @for $i from 0 through 20 {
    $perc: $i * 5;

    &--#{$perc} {
      @extend %col;
      width: #{$perc}#{"%"};
    }
  }

  &--nogutter {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
}

@media only screen and (max-width: $mobile_break) {

  .col {
    @for $i from 0 through 20 {
      $perc: $i * 5;

      &--#{$perc} {
        width: 100% !important;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
