.title {
  font-weight: normal;
  color: #2a2a2a;
  margin-bottom: 20px;
  font-size: 24px;

  &--small {
    @extend .title;
    font-size: 14px;
    line-height: 16px;
  }

  &--medium {
    @extend .title;
    font-size: 18px;
    line-height: 20px;
  }

  &--large {
    @extend .title;
    font-size: 24px;
    line-height: 26px;
  }

  &--gray {
    @extend .title;
    color: #828282 !important;
  }
}
