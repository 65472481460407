.counter {
  background: #f7f7f7;
  z-index: 5;
  display: flex;
  position: fixed;
  min-height: 66px;
  height: auto;
  padding:0;
  width: 100%;
  justify-content: flex-end;
  font-weight: bold;

  &__wrap {
    color: #fff;
    background: #505050;
    display: flex;
    width: 100%;
    min-height: 66px;
    justify-content: space-around;
    align-items: center;
  }

  &__col {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 1;
    height: 100%;
    justify-content: space-around;
    padding: 4px 0;

    &--big {
      flex: 2;
    }

    label {
      font-size: 13px;
    }
  }

  &__row {
    h3 {
      font-size: 26px;
      border-right: 1px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      font-size: 14px;

      &.activation-plan-name {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: normal;
      }
    }
    md-icon {
      font-size: 14px;
      height: 20px;
    }
  }
}

.time-gmt {
  position: absolute;
  width: 100%;
  top: calc(100% + 5px);
  font-size: 11px;
  color: #505050;
  text-align: center;
  font-weight: normal;
}

@media only screen and (min-width: $mobileFirst_break) {
  .counter__wrap,
  .time-gmt {
    width: 440px;
  }
  .time-gmt {
    left: initial;
    right: 0;
    text-align: left;
    padding-left: 5px;

    &--no-counter {
      text-align: right;
      padding-right: 5px;
    }
  }
}
