.dialog {
  &__header {
    position: absolute;
    width: 100%;
    height: 46px;
    line-height: 46px;
    top: 0;
    left: 0;
    padding: 0 20px;
    background: $red;
    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
    box-shadow: 0 3px 2px 0 rgba(0,0,0,.14);
    font-size: 16px;

    span {
      @extend .truncate;
      width: 94%;
      display: inline-block;
    }
  }
  &__header--negative {
    background-color: transparent;
    box-shadow: none;
    font-size: 24px;
    color: #444;
    line-height: 3em;

    .dialog-close-button:hover:before,
    .dialog-close-button:active:before {
      color: red;
    }
  }
}

.confirm-buttons {
  clear: both;
  margin-top: 30px;
  @extend .buttons--right;
}

.dialog-close-button {
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.dialog-close-button:before {
  font-family: 'Helvetica', Arial, sans-serif;
  cursor: pointer;
  background: transparent;
  border-radius: 3px;
  content: '\00D7';
  font-size: 26px;
  font-weight: 400;
  height: 30px;
  line-height: 40px;
  position: absolute;
  right: 3px;
  text-align: center;
  top: 3px;
  width: 30px;
}

.dialog-close-button:hover:before,
.dialog-close-button:active:before {
  color: #fff;
}
.md-dialog-content .md-title {
  margin-bottom: 8px;
}
