%btnReset {
  font-weight: normal;
  font-size: 11px;
  padding: 0 $catalog-gutter-half;
  text-decoration: underline;
  transition: all 0.3s ease-out;

  &:hover {
    color: red;
    text-decoration: none;
  }
}

.catalog-filters {
  width: 232px;
  margin-right: $catalog-gutter-double;
  border-radius: 5px;

  > * {
    margin-bottom: $catalog-gutter;
    border-radius: 5px;
    overflow: hidden;
  }
}
.catalog-filters_list {
  background-color: $catalog-color-light-gray;
  border-radius: 5px;
  margin-bottom: $catalog-gutter;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .catalog-filters-header {
    font-size: 14px;
    line-height: 1.4;
    padding: $catalog-gutter $catalog-gutter;
    margin-bottom: $catalog-gutter;
    background-color: $catalog-color-dark-gray;
    border-radius: 5px 5px 0 0;
    // reset/ovewrite shared styles
    position: static;
    height: auto;
    z-index: 0;
  }
}
.catalog-filter_group {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: $catalog-gutter;
  display: flex;
  flex-direction: column;

  > .name {
    padding: 0 $catalog-gutter 0 $catalog-gutter-double;
    margin-bottom: $catalog-gutter-half;
    font-size: 14px;
    display: flex;
    align-items: center;

    > .icon {
      margin-left: -$catalog-gutter-half;
      transform: rotate(90deg) scale(0.7, 0.8);
      cursor: pointer;
      transition: all 0.15s ease-out;
    }
    > .label {
      flex: 1;
    }
    > .reset {
      @extend %btnReset;
    }

    > .fds {
      transform: scale(0.75);
      margin-right: $catalog-gutter;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        > .icon {
          color: $red;
        }
      }

      > .icon {
        margin-right: $catalog-gutter-half;
        transition: all 0.15s ease-out;
      }
    }
  }

  .search-bar {
    padding: 0 $catalog-gutter;
    margin-bottom: $catalog-gutter;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;

    > .field {
      border: 1px solid $catalog-color-dark-gray;
      border-right: 0;
      border-radius: 5px 0 0 5px;
      padding: $catalog-gutter;
      width: 100%;
      font-size: 12px;

      &::-ms-clear {
        display: none;
      }
    }
    > .icon {
      background-color: #fff;
      border: 1px solid $catalog-color-dark-gray;
      border-left: 0;
      border-radius: 0 5px 5px 0;
      color: $catalog-color-text;
      display: flex;
      align-items: center;
      flex-basis: 34px;
    }
  }

  .wrapper {
    width: calc(100% - #{$catalog-gutter-double});
    max-height: calc((26px + #{$catalog-gutter-half}) * 3);
    font-size: 12px;
    overflow-y: auto;
  }

  &.is-collapsed {
    > .search-bar,
    > .wrapper {
      display: none;
    }
    > .name {
      > .icon {
        transform: rotate(0deg) scale(0.7, 0.8);
      }
    }
  }
}
.catalog-filter_item {
  background: $catalog-color-light-gray;
  padding-left: $catalog-gutter-double;
  margin-bottom: $catalog-gutter-half;
  overflow: hidden;
  line-height: 1.2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  > .toggle {
    margin: 0 !important;
  }
  > .name {
    @extend .truncate;

    margin-right: $catalog-gutter-half;
    max-width: calc(100% - #{$catalog-gutter-double * 3});
  }
  > .count {
    font-weight: bold;
  }
}

.catalog-filters_list--selected {
  @extend .catalog-filters_list;

  .catalog-filters-header {
    margin-bottom: 0;
    padding-right: $catalog-gutter-half;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .reset {
      @extend %btnReset;
    }
  }
  &:last-child {
    .catalog-filter_item:last-child {
      border: none;
    }
  }

  > .catalog-filter_group {
    margin-bottom: 0;

    &:last-child {
      .catalog-filter_item:last-child {
        background: red;
        border: none;
      }
    }
  }

  .catalog-filter_item {
    border-bottom: 1px solid $catalog-color-dark-gray;
    margin-bottom: 0;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: darken($catalog-color-light-gray, 1%);
      color: $red;
    }

    > .remove {
      width: $catalog-gutter;
      margin-right: $catalog-gutter;
      height: 2em;
      margin-top: 0;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in;
    }
  }
}

.catalog-filters_list__btsMobile,
.catalog-filters_list .btCloseFilters {
  @extend  .material-icons;
  display: none;
}

@media only screen and (max-width: $catalog_break) {
  .catalog-filters_list .btCloseFilters {
    position: absolute;
    right: 0;
    height: 40px;
    top: 0;
    line-height: 40px;
    width: 40px;
    text-align: center;
    display: block;
  }
  .catalog-filters_list__btsMobile {
    position: relative;
    height: 36px;
    margin: 10px;
    display: flex;

    .button--confirm {
      flex: 1;
      margin: 0;
      border: none;
      text-align: left;
      padding: 0 20px;
      border-radius: 4px;
    }

    .btCloseFilters {
      height: 100%;
      color: #fff;
      line-height: 34px;
    }
  }

  .catalog-filters_list--selected {
    width: 100%;
    margin-bottom: 20px;

    .catalog-filters-header {
      display: none;
    }
  }

  .catalog-filter_group {
    .wrapper {
      max-height: 100%;
    }
  }
}
