ko-form-time-select {
  width: 100%;
  //max-height: 40px;

  .form-item {
    @extend .form-item__input;
    padding: 0;

    &.error {
      border-color: #df3f17;
    }

    .two-points {
      min-width: 5px;
      margin: 0 1%;
      display: inline-block;
      vertical-align: top;
      font-weight: bold;
    }

    .select-start-time {
      margin: 0;
      padding: 0;
      width: 44%;
      display: inline-block;
      border: 0 none;
      height: 100%;

      .md-select-value {
        min-width: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        height: 100%;

        span:first-child {
          width: 90%;
          display: inline-block;
          height: 100%;
        }

        .md-select-icon {
          //height: 100%;
          margin: 0;
          line-height: 100%;
          width: 10%;

          &::after {
            height: 100%;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            line-height: 100%;

          }
        }
      }

    }

  }

}
