.tableGrid {
  background: none;
  border-collapse: collapse;
  font-size: 14px;
  margin-left: 1px;
  margin-top: 10px;
  width: calc(100% - 2px);

  .md-button {
    margin: 6px 0;
  }

  thead {
    color: $table-grid-gray;
    font-size: 11px;
    line-height: 18px;

    td {
      padding: 4px;
    }
  }

  tbody {
    font-size: 12px;

    .form-item__input {
      background: none;
      border: 0;
      height: 28px;
      line-height: 12px;
      &.error {
        border: 1px solid $red;
      }
    }

    td[align="center"] .form-item__input {
      padding-left: 5px;
      padding-right: 5px;
      text-align: center;
    }
    td.disabled {
      opacity: 0.35;
    }

    tr {

      &.active {
        border: 2px solid $table-grid-darker-gray;
      }

      &.line {
        background: $color-alabaster;
      }

      td {
        padding: 0 4px;
      }
    }

    a {
      color: $color-blue-ribbon;
      text-decoration: underline;
    }

  }

  &--white {
    tbody tr {
      background: $color-white;
      border: 1px solid $table-grid-lighter-gray;
    }
    md-switch {
      max-width: 40px;
    }
  }

  &--transparent-last-column {
    tbody {
      tr {
        background: none;
        border: 0;

        &.line {
          background: none;
        }

        &.empty {
          background: $color-alabaster;
        }
      }

      .line td {
        background: $color-alabaster;
      }

      td {
        background: $color-white;
        border: 1px solid $table-grid-lighter-gray;

        &:last-child {
          background: none;
          border: 0;
          &:first-child {
            background: $color-white;
            border: 1px solid $table-grid-lighter-gray;

          }
        }

        &:first-child {
          border-left: 1px solid $table-grid-lighter-gray;
        }
      }

      .form-item__input {
        background: none;
        border: 1px solid $table-grid-lighter-gray;
        line-height: 12px;
        &.error {
          border: 1px solid $red;
        }
      }
    }
  }

  &--transparent-first-column {
    tbody {
      tr {
        background: none;
        border: 0;

        &.line {
          background: none;
        }

        &.empty {
          background: $color-alabaster;
        }
      }

      .line td {
        background: $color-alabaster;
      }

      td {
        background: $color-white;
        border: 1px solid $table-grid-lighter-gray;

        &:first-child {
          background: none;
          border: 0;
          &:last-child {
            background: $color-white;
            border: 1px solid $table-grid-lighter-gray;

          }
        }

        &:last-child {
          border-left: 1px solid $table-grid-lighter-gray;
        }
      }

      .form-item__input {
        background: none;
        border: 1px solid $table-grid-lighter-gray;
        line-height: 12px;
        &.error {
          border: 1px solid $red;
        }
      }
    }
  }

  &--striped {
    tbody tr {
      border: 1px solid $table-grid-lighter-gray;
    }
    tbody tr.error {
      border: 2px solid $red;
    }
    tbody tr:nth-child(even) {
      background: $color-alabaster;
    }
    tbody tr:nth-child(odd) {
      background: $color-white;
    }
  }

  &--borderAll {
    tbody tr {
      border-style: none;

      td {
        border: 1px solid $table-grid-lighter-gray;
      }
    }
  }

  &--padMedium {
    tbody tr td {
      padding: 4px;
    }
  }

  &--padLarge {
    tbody tr td {
      padding: 8px;
    }
  }

  &--2-columns {
    td {
      width: 50%;
    }
  }

  &--freezer {
    width: 650px;
    margin: 50px auto;
    thead {
      font-size: 14px;
    }
    tbody {
      md-select {
        background: $color-white;
        border: 1px solid $table-grid-lighter-gray;
        height: 40px;
        line-height: 40px;
      }
      .col--50,
      .col--25 {
        padding: 0;
      }
      tr td:first-child,
      tr.error td:first-child {
        background: $color-white;
        border: none;
        font-size: 16px;
      }

      tr.error td {
        border: 2px solid red;
      }
    }
  }

  &--accessory {
    max-width: 600px;
    margin-right: 6px;
    tbody {
      tr td:last-child {
        border: none;
        background: $color-gallery;
      }
    }
    .md-button {
      height: 30px !important;
      margin: 0 !important;
      min-height: 20px !important;
      min-width: 20px !important;
      padding: 5px 5px 0 5px !important;
      width: 30px !important;
    }

    .material-icons {
      font-size: 18px;
      vertical-align: top;
    }
  }

  &--prototype {
    tbody {
      tr td:first-child {
        border: none;
        background: $color-gallery;
      }
      tr td:first-child.error {
        border: 1px solid #ff0000;
      }
    }
  }

  &--scrollableBody {
    display: block;

    [align="center"] {
      text-align: center;
    }

    thead {
      display: block;
    }

    tbody {
      display: block;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    tr {
      display: block;

      &:last-child {
        padding-right: 15px;
      }

      &:first-child {
        padding-left: 15px;
      }
    }
    td {
      display: inline-block;

      &:last-child {
        padding-right: 0px;
      }

      &:first-child {
        padding-left: 0px;
      }
    }
  }

  &--transparent-first-column.tableGrid--scrollableBody {
    tr {
      vertical-align: middle;
      display: inline-table;
      width: 100%;
    }
    thead tr:first-child {
      padding: 0;
    }
    td {
      display: table-cell;
      min-height: 42px;
      vertical-align: middle;
    }

  }
}

.ngdialog {
  .tableGrid th {
    white-space: nowrap;
  }
}

.line--highlight {
  background: $color-alabaster;
}

.table2cols {
  tbody td:first-child {
    border-right: 1px solid $table-grid-lighter-gray;
  }
}
