composed-name {
  max-width: 100%;
}

.composed-name {
  padding: 10px 0;
  position: relative;
}

.composed-name_name {
  @extend .main-col;

  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-indent: 25px;
  line-height: 20px;
  text-decoration: underline;
  text-transform: uppercase;
}

.composed-name_star {
  position: absolute;
  top: 0.5em;
  left: 0;
}

.composed-name_description {
}
