.angucomplete-holder {
  width: 50%;
  position: relative;
  min-height: 38px;
  height: 100%;
  padding-top: 8px;
  float: left;
}

.angucomplete-dropdown {
  border-color: #ececec;
  border-width: 1px;
  border-style: solid;
  border-radius: 0 0 5px 5px;
  width: 100%;
  cursor: pointer;
  z-index: 9999;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
}

.angucomplete-searching {
  color: #acacac;
  font-size: 14px;
  height: 37px;
  line-height: 37px;
  padding: 0 10px;
}

.angucomplete-description {
  font-size: 14px;
}

.angucomplete-row {
  // padding: 5px;
  line-height: 38px;
  // margin-bottom: 4px;
  border-top: 1px solid #d8d8d8;
  padding: 0 10px;
}

.angucomplete-selected-row, .angucomplete-row:hover {
  background-color: lightblue;
  color: #ffffff;
}

.angucomplete-image-holder {
  padding-top: 2px;
  float: left;
  margin-right: 10px;
  margin-left: 5px;
}

.angucomplete-image {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border-color: #ececec;
  border-style: solid;
  border-width: 1px;
}

.angucomplete-image-default {
  /* Add your own default image here
  background-image: url('/assets/default.png');
  */
  background-position: center;
  background-size: contain;
  height: 34px;
  width: 34px;
}

.angucomplete-items-input {
  float: left;
  width: 100%;
  margin-bottom: 7px;
  border: none;
  outline: none;
  height: 20px;
  line-height: 20px;
}

// .angucomplete-items {
//   display: inline-block;
//   width: 100%;
// }

.angucomplete-items .angucomplete-items-list {
  padding: 0;
  margin: 0;
  list-style: none;
  // float: left;
}

// .angucomplete-items-input {
//   padding: 0;
//   margin: 0;
// }

.angucomplete-item-value {
  // font-family: inherit;
  // font-size: 14px;
  // font-weight: normal;
  // height: 100%;
  // line-height: 1;
  // padding-bottom: 5px;
  // padding-left: 0;
  // padding-right: 0;
  // padding-top: 0;
  // margin: 0;
  // vertical-align: top;
  // display: inline-block;
  // position: relative;
  float: left;
}

.angucomplete-item-input {
  width: 100%;
}

.angucomplete-item-value-text {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
}

.angucomplete-item-delete {
  // bottom: 1px;
  // cursor: default;
  // display: inline-block;
  // height: 16px;
  // line-height: 1;
  // position: absolute;
  // right: -1px;
  // width: 16px;
  // vertical-align: text-bottom;
  float: right;
  padding: 0 5px;
  border-left: 1px solid #d6d6d6;
  margin-left: 5px;
}

.angucomplete-item-delete-all {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
}

.angucomplete-item-selected {
  display: block;
  position: relative;
  padding-left:5px;
  background: #f2f2f2;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  height: 20px;
  line-height: 20px;
  margin-bottom: 7px;
  margin-right: 10px;
}