.koFormMultiSelectList {
  padding: 0;
  .md-text {
    width: 100%;
    height: 100%;

    md-checkbox {
      margin: 0;
      height: 100%;
      .md-container {
        margin-left: 10px;
      }
      .md-label {
        margin: 6px 0 0 18px;
      }
    }
  }
}
