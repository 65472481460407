.main-nav-blackdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 3;
  opacity: 0;
  background: #fff
}

.main-nav {
  left: 0;
  position: fixed;
  overflow: auto;
  transform: translateX(-100%);
  width: 100%;
  max-width: 385px;
  height: calc(100vh - 65px);
  top: 65px;
  z-index: 20;
  background-color: #fff;
  transition: all .8s;
  .menu {
    transition: all .5s;
    width: 100%;
    //height: 100%;
    margin: 0;
    transform: translateX(-100%);
    padding: 20px 0 0 0;
    font-size: 14px;
  }
  &.open {
    transform: translateX(0);
    box-shadow: 10px 0px 9px -6px rgba(0,0,0,0.15);
    .menu {
      transform: translateX(0);
    }
  }
  .primary,
  .secondary,
  .tertiary {
    list-style: none;
      padding: 5px 0 5px 25px;
      position: relative;
      color: #4f4f4f;
      transition: all 0.5s ease-out;
      width: 100%;
      margin: 0;
      border-radius: 0;
      text-align: left;
      text-transform: none;
      font-weight: normal;
        .ico {
          font-size: 20px;
          left: -17px;
          position: relative;
        }
        &.active {
          background-color: #F2F2F2;
          &:before {
            content: "";
            position: absolute;
            width: 5px;
            height: 100%;
            background-color: #ff1212;
            top: 0;
            left: 0;
          }
        }
        &:hover {
          transition: all 0.5s ease-out;
          &:before {
            content: "";
            position: absolute;
            width: 5px;
            height: 100%;
            background-color: #7d7d7d;
            top: 0;
            left: 0;
          }
        }
  }
  .secondary {
      padding-left: 50px;
  }
  .tertiary {
    padding-left: 80px;
  }
}

.sub-nav {
  display: none;
  width: 80%;
  float: left;
  margin: 85px 2% 2% 2%;
  position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 110px;
    z-index: 11;
    border-radius: 7px;
  &.open {
    display: block;
  }
  ul {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    li {
      position: relative;
      list-style: none;
      background-color: #fff;
      // padding: 8px 0 8px 30px;
      a {
        color: #7d7d7d;
        text-decoration: none;
        font-weight: 600;
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 8px 0 8px 30px;
        transition: all 0.3s ease-out;

        &:before {
          content: " ";
          background-color: transparent;
          width: 4px;
          height: 100%;
          top: 0;
          left: -4px;
          position: absolute;
          transition: all 0.3s ease-out;
        }

        &:hover {
          color: #333;
          &:before {
            background-color: #7d7d7d;
            left: 0;
          }
        }
      }

    }
  }

  .active {
    background-color: #f3f3f3;

    &:before {
      content: " ";
      background-color: #ff1212;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
    a {
      color: #303030;
    }
  }
  .edgeTop {
    background-color: #fff;
    height: 20px;
    border-radius: 7px 7px 0 0;
  }
  .edgeBottom {
    background-color: #fff;
    height: 20px;
    border-radius: 0 0 7px 7px;
  }
}

@media only screen and (min-width: 641px) {
  .sub-nav {
    z-index: 1;
    width: 20%;
    position: relative;
      left: 0;
      transform: translateX(0);
      top: 0;
      ul {
        overflow-y: hidden;
      }
  }
}
