.warnningForm {
  width: 100%;
  display: inline-block;
  background: #fffbc0;
  font-size: 14px;
  padding: 10px 20px;
  margin-bottom: 10px;

  p {
    float: left;
    line-height: 16px;
    font-size: 13px;
    color: #505050;
    margin-top: 3px;
    width: 60%;
  }

  p.singleButton {
    width: 82%;
    padding: 9px;
  }

  .button--cancel {
    background: #f0f0f0;
  }

  .md-button {
    margin: 0 4px;
  }
}
