/**
######################################
## Material Design COMPONENTS (mdc) ##
######################################
**/

/* START - Circle Image */
.mdc-circle-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: auto;
  padding: 0;
  overflow: hidden;
  background: rgba(158,158,158,.2);
  box-shadow: 0 1px 1.5px 0 rgba(0,0,0,.12),0 1px 1px 0 rgba(0,0,0,.24);
  position: relative;
  line-height: normal;

  &--large {
    @extend .mdc-circle-image;
    height: 62px;
    width: 62px;
  }
  &--small {
    @extend .mdc-circle-image;
    height: 35px;
    width: 35px;
  }
}

.mdc-input {
  outline: none;
  height: 38px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 10px;
  line-height: 38px;
}
/* END - Circle Image */


/* START - Badge */
.mdc-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px;
}

.mdc-badge:not([data-badge]) {
  margin-right: auto;
}

.mdc-badge[data-badge]:after {
  content: attr(data-badge);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: -11px;
  right: -11px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f40000;
  color: rgb(255,255,255);
}
/* END - Badge */

