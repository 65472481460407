$primary_color: #8bc34a;
$secundary_color: #ffab40;
$error_color: #df3f17;
$primary_bg: #f2f2f2;
$secundary_bg: #d1d1d1;
$cart-color-border: #d2d2d2;
$warning_color: orange;
$success_color: green;
$red: #ff1212;
$gray: rgba(154, 154, 154, 0.5);
$enabled: #9e9e9e;
$disabled: #ccc;
$mobile_break: 640px;
$mobileFirst_break: 641px;
$catalog_break: 810px;
$laptop_break: 1024px;
$laptopL_break: 1440px;
$gutter: 10px;
$gutter-half: 5px;
$gutter-double: 20px;
$table-grid-gray: #7e7e7e;
$table-grid-darker-gray: #878787;
$table-grid-lighter-gray: #d8d8d8;
//color names from http://chir.ag/projects/name-that-color/
$color-alabaster: #fafafa;
$color-blue-ribbon: #1845fc;
$color-white: #fff;
$color-gallery: #efefef;
$color-gray: #878787;
$replan-color: #ffcccc;
