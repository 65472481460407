.header__section .userNav {
  display: none;
}
.main-nav .main-nav-blackdrop {
  display: none;
}
.userNav {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
  flex-direction: column;

  ul {
    margin-top: 10px;
    li {
      &.endgroup {
        border-bottom: 1px solid #e3e3e3;
      }
      button, a {
        padding: 0 8px 0 35px;
        text-align: left;
        width: 100%;
        margin: 0;
        text-transform: capitalize;
        font-weight: normal;
        min-height: 34px;
        line-height: 34px;
        md-icon {
          width: auto;
        }
      }
    }
  }
}

.userProfile {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 6px 12px;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  &__image {
    margin: 6px 0 16px 0;
    border-radius: 50%;
    font-size: 20px !important;
    background: #9e9e9e;
    color: #fff;
    padding: 7px;
    background: #454545;
    //border: 2px solid #454545;
  }
  &__info {
    display: flex;
    flex-direction: column;
    text-align: left;

    &__name {
      font-weight: bold;
      font-size: 14px;
      color: #353535;
      margin-bottom: 4px;
    }

    &__email {
      font-size: 12px;
      color: #ccc;
      i {
        float: right;
        font-size: 16px;
        color: #000;
        transition: transform .5s;
      }
    }
  }
}

.userNav.opened {
  .userProfile__info__email i {
      transform: rotate(180deg);
  }
}

@media only screen and (min-width: $mobileFirst_break) {
  .userNav {
    border-bottom: none;
  }
  .main-nav .userNav {
    display: none;
  }

  .header__section .userNav {
    display: block;
    position: relative;
    ul {
      min-width: 190px;
      position: absolute;
      background: #fff;
      margin-top: 0;
      top: 100%;
      right: 0;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      z-index: 4;
      li {
        button, a {
          padding: 0 16px;
          min-height: 36px;
          line-height: 36px;
          font-size: 15px;
        }
      }
    }
  }

  .userProfile {
    flex-direction: row-reverse;
    padding: 12px 12px 0 0;
    &__info {
      justify-content: center;
      margin-right: 8px;
      align-items: flex-end;
      &__name {
        font-size: 12px;
        margin-bottom: 0;
      }

      &__email {
        color: #6f6f6f;
        font-size: 11px;
        i {
          display: none;
        }
      }
    }
  }   
  .userActions{
    width: 43px; 
    height: 38px; 
    display: inline-block;
    margin-bottom: 15px;
  }
}

// .grid-user-data {
//   min-width: 292px;
//   .user-info {
//     display: inline-block;
//     padding: 15px 0;
//     line-height: 1;
//   }
//
//   .email {
//     font-size: 11px;
//     color: #ccc;
//   }
//
//   .avatar {
//     display: inline-block;
//     vertical-align: top;
//     margin: 6px 10px 6px 0;
//     cursor: pointer;
//
//     i {
//       border-radius: 50%;
//       font-size: 20px;
//       background: #9e9e9e;
//       color: #fff;
//       padding: 8px;
//     }
//   }
// }
