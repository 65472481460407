.optSelectHeader {
  margin-right: 12px;
  max-width: 234px;

  &--flex {
    width: auto;
    max-width: 234px;
    min-height: 1em;
    position: relative;
    h3 {
      padding-right: 30px;
    }
  }

  &--icon::after {
    font-size: 11px;
    content: '\25B6';
    display: block;
    position: absolute;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    margin: 0 0 0 15px;
    width: 20px;
    height: 24px;
    padding-top: 8px;
    padding-left: 2px;
    top: 3px;
    right: 0;
    background: #fff;
    text-align: center;
  }

  &--icon:last-child::after {
    display: none;
  }

  &.adherenceTracking {
    max-width: 250px;
  }

  &__title {
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  md-select {
    padding: 0;
    border: none;
    font-size: 24px;
  }

  md-select.md-koTheme-theme .md-select-value {
    padding: 0;
  }

  md-select .md-select-value .md-select-icon {
    border: 1px solid #d8d8d8;
    margin: 0 0 0 15px;
    border-radius: 6px;
    height: 34px;
  }

  .md-select-value .md-select-icon:after {
    top: -5px;
    transform: scaleY(1) scaleX(1);
    right: 5px;
    font-size: 11px;
    content: '\25B6';
  }
}
