body {
  &.public,
  &.user-change-password {
    background: #fff;
  }
  &.login main {
    margin-top:0;
    display: table;
    padding-bottom: 70px;
    position: relative;
    min-height: 100vh;
  }
}

.auth {
  &__container {
    max-width: 380px;
    margin: 0px auto 50px auto;
    &--login {
      margin-bottom: 10px;
    }
  }

  &__logo {
    text-align: center;
    padding: 15px 0;

    &__text {
      display: block;
      font-size: 30px;
      font-weight: bold;
      padding: 10px 0 0;
    }

    &__svg {
      display: inline-block;
      width: auto;
      height: 72px;
    }
  }

  &__form {
    position: relative;
  }

  &__forgot-password {
    text-align: right;
    font-size: 87%;

    a:hover,
    a:focus {
      text-decoration: underline;
    }
  }

  &__avatar {
    text-align: center;

    &__ico {
      border: 3px solid #aeaeae;
      border-radius: 50%;
      font-size: 50px !important;
      padding: 10px;
      color: #aeaeae;
      background: #fff;
      margin: 10px 0 0;
    }

    &__title {
      margin: 20px 0;
      font-weight: bold;
    }

    &__message {
      margin: 15px 0;
      font-size: 87%;
    }

  }

  &__box {
    background: #f2f2f2;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  &__buttons {
    overflow: hidden;

    &--change-img {
      margin: 0;
    }
    button {
      text-transform: uppercase;
      padding: 0 20px;
      font-size: 12px;
      max-width: 154px;
      width: 87%;
      &.button--confirm {
        width: auto;
        max-width: 154px;
      }
    }
  }

  .expired-link {
    line-height: 200px;
    text-align: center;
  }
}

/* Only mobile */
@media only screen and (max-width: 768px) {
  .auth {
    &_logo {

    }

    &__logo {
      padding: 10px 0;

      &__text {
        font-size: 25px;
      }

      &__svg {
        display: inline-block;
        width: auto;
        height: 62px;
      }
    }
    &__box {
      background: #f2f2f2;
      padding: 5px 10px;
      border: 1px solid #ddd;
      margin: 0px 5px;
    }
  }
}

/* Tablet >= 600px   */
@include tablet-portrait {
  .auth {
    display: flex;
    height: 85vh;
    align-items: center;
  }
}
