// .koZoomImage-zoom {
//     margin-left: 4em;
// }
.koZoomImage {
  width: 400px;
  height: 400px;
  position: relative;

  &__loader {
    background: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;

    md-progress-linear {
      width: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.koZoomImage-original {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.koZoomImage-zoom {
  position: absolute;
  overflow: hidden;
  left: 410px;
  top: 0;
  background: #fff;
  border: 1px solid #e4e4e4;
  display: none;
}


.koZoomImage-original img{
  //width: 100%;
  max-height: 100%;
  max-width: 100%;
}

.koZoomImage-zoom img{
  height: 600px;
  position: relative;
  max-width: inherit;
}

.mark {
  opacity: .3;
  border: 1px dotted #fff;
  background: #eee;
}

.koImageZoomHide {
  visibility: hidden;
}
