$label_height: 20px;
$input_height: 40px;
$input_border: 1px solid #d8d8d8;
$input_focus_border: 1px solid #98b5f2;
$border_color: #cdcdcd;

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}

.form--content {
  padding: 0 30px;

  .form-buttons {
    height: 40px;
  }
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.form-legend {
  font-size: 10px;
  margin-top: 10px;

  span {
    float: left;
    clear: both;
  }
}

.form-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

ko-button-download .form-item--download .form-item__input.truncate {
  padding-right: 86px;
}


.form-item {
  position: relative;
  // overflow: hidden;
  padding: 0 0 15px;

  .innerTableGrid {
    tr td {      
      border: 0;      
      a {
        text-decoration: none;
      }
    }
  }

  &--full {
    margin-left: -20px;
    margin-bottom: 20px;
    width: calc(100% + 40px);
    max-height: 290px;
    padding: 0;
    border-bottom: 1px solid #dcdcdc;
    overflow: auto;

    .tableGrid tbody tr td {
      padding-left: 12px;
    }    
  }

  

  &__label {
    display: block;
    height: $label_height;
    line-height: $label_height;
    padding: 0 3px;
    font-size: 11px;
    color: #505050;
    font-weight: bold;
    width: 100%;

    &.required:after {
      display: inline;
      content: '*';
      margin-left: 2px;
    }

    &.largeFont {
      font-size: 16px;
    }

    &--no-bold {
      @extend .form-item__label;
      font-weight: normal;
    }
  }

  &__text {
    display: block;
    width: 100%;
    padding: 0 3px;
    font-size: 13px;
    font-weight: bold;
  }

  &__checkbox {
    @extend .form-item;
    padding-top: $label_height;
    padding-left: 0;
  }

  &--spaced {
    margin-top: $label_height;
  }

  &__input {
    background: #fff;
    border: $input_border;
    border-radius: 3px;
    color: #000;
    font-size: 11px;
    padding: 0 15px;
    height: $input_height;
    line-height: $input_height;
    width: 100%;
    margin: 0;
    display: block;

    &--summary {
      @extend .form-item__input;
      /*border: 0;*/
    }

    &:disabled {
      @extend .disabled-field;
    }

    &:focus {
      border: $input_focus_border;
    }

    &.error {
      border: 1px solid $red;
    }
  }

  &.col--50:first-child:last-child {
    padding-right: 15px;
  }

  .form-item--download {
    position: relative;

    .form-item__input {
      width: 98%;
      border-right: none;
      display: inline-block;
      border-radius: 3px 0 0 3px;
    }
    .form-item__button {
      @extend .button--cancel;
      /*width: 35%;*/
      width: 86px;
      height: 100%;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px 8px;
      border-radius: 0 3px 3px 0;
      background-color: #f0f0f0;

      &:hover {
        background-color: #dedede;
      }
    }
    &[disabled] {
      opacity: 0.65;
      pointer-events: none;
    }
  }

  &__textarea {
    background: #fff;
    border: $input_border;
    border-radius: 3px;
    color: #000;
    font-size: 11px;
    padding: 15px 15px;
    height: auto;
    line-height: normal;
    width: 100%;
    margin: 0;
    min-height: 120px;
    display: block;
    resize: none;

    &--rich-text {
      padding: 0;
    }

    @include placeholder {
      color: #b9b9b9;
    }

    &--summary {
      @extend .form-item__input;
      border: 0;
    }

    &:focus {
      border: $input_focus_border;
    }

    &:disabled {
      @extend .disabled-field;
    }

    &.error {
      border: 1px solid $red;
    }
  }
  &.col--30 {
    width: 33%;
  }
}

.fileUploadedView {
  @extend .form-item__input;
  @extend .truncate;
  background: #fff;
  padding: 6px 10px;
  color: #1845fc;
  text-decoration: underline;
  line-height: 29px;
}

p.form-item__textarea {
  background: #f5f5f5;
}

span.form-item__input {
  background: #f5f5f5;
  min-height: 40px;
  height: auto;
  overflow: hidden;
  line-height: inherit;
  padding-top: 6px;
}

.captcha {
  &__image {
    background: #fff;
    border: $input_border;
    border-radius: 3px;
    /*padding: 0 15px;*/
    padding: 0;
    height: $input_height;
    line-height: $input_height;
    width: 100%;
    margin: 0;
    display: block;
    text-align: center;

    img {
      height: 38px;
    }
  }

  &__reload {
    text-align: right;
    font-size: 11px;
    color: #4285f4;
    text-decoration: underline;
  }

  &__input {
    text-transform: uppercase;

    &::-webkit-input-placeholder {
      text-transform: none;
    }
    &:-moz-placeholder {
      text-transform: none;
    }
    &::-moz-placeholder {
      text-transform: none;
    }
    &:-ms-input-placeholder {
      text-transform: none;
    }
  }
}

.form-item-details {
  @extend .form-item;
  float: left;
  &.col--30,
  &.col--50,
  &.col--65 {
    padding-right: 2px;
    padding-left: 2px;
  }
  &.col--65 {
    width: 66%;
  }
  &.compactTextArea {
    .form-item__textarea {
      height: 60px;
      min-height: 60px;
    }
  }

  &.col--30 md-datepicker .md-button.md-icon-button {
    width: 46px;
    margin: 0;
  }
}

.ko-upload-file {
  width: 100%;
  padding-bottom: 20px;
}

.form-item-half {
  @extend .form-item;
  float: left;
  margin: 0;
  padding: 0 2px;

  &.col--50,
  &.col--30 {
    padding-right: 2px;
    padding-left: 2px;
  }

  .md-koTheme-theme .md-datepicker-input-container {
    //width: 72%;
    width: calc(100% - 48px);
    margin-left: 0;
    padding-bottom: 0;
  }

  .md-koTheme-theme .md-datepicker-input {
    width: 100%;
    min-width: 100%;
  }

  md-radio-button {
    margin: 2px;
    .md-label {
      margin-left: 4px;
    }
  }

  md-datepicker.md-koTheme-theme {
    .md-button.md-icon-button {
      width: 27px;
      margin: 0 0 0 3px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.form-item-status {
  @extend .form-item;
  border: $input_border;
  height: $input_height;
  line-height: $input_height;
  border-radius: 3px;
  padding: 0 15px;

  &__label {
    float: left;
    padding: 8px 15px 0 6px;
    @extend .form-item__label;
    width: auto;
  }

  md-switch {
    float: right;
    margin: 8px 0;
  }

  &.col--50:last-child {
    margin-left: 15px;
    width: calc(50% - 15px);
  }
}

.form-item-range {
  @extend .form-item;

  &__label {
    @extend .form-item__label;
    display: inline-block;
  }

  &__input {
    @extend .form-item__input;
    display: inline-block;
    width: 100px;
  }
}

.form-buttons {
  clear: both;
  margin-top: 30px;
  @extend .buttons--right;
}

.form__disabled {
  *,
  *[disabled],
  *[disabled]:hover,
  *[disabled]:focus {
    opacity: .8;
    cursor: not-allowed;
    pointer-events: none;
  }

  label,
  input,
  select,
  md-select {
    cursor: not-allowed;

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      border: $input_border;
      cursor: not-allowed;
    }
  }
}

.form-item-upload {
  @extend .form-item;
  margin-bottom: 16px;

  .col--25 {
    padding-bottom: 0;
  }

  .col--75 {
    padding: 0;
    position: relative;
  }

  .col--100 {
    padding: 0;
    position: relative;
  }

  &__image {
    max-height: 140px;
    overflow: hidden;
  }

  &__fields {
    width: 100%;
    display: inline-block;

    &--bottom {
      position: absolute;
      bottom: 0;
      left: 0;

      label {
        width: auto;
        float: left;
        line-height: 30px;
      }
    }

    &--details {
      display: inline-block;
      line-height: 20px;
      padding: 0 3px;
      font-size: 11px;
      color: #505050;
      font-weight: bold;
    }

    &--position-inherit {
      position: inherit
    }

    &--anchor {
      height: 20px;
      line-height: 20px;
      padding: 0 3px;
      font-size: 13px;
      text-decoration: underline;
    }

    md-radio-group {
      float: left;
      md-radio-button {
        margin: 0 8px;
      }
    }
  }
}

// sumarize (autocomplete)
ko-summarize, .ko-summarize {
  display: block;
  /*border: $input_border;*/
  border-radius: 3px;

  &.error {
    .custom-chips.md-koTheme-theme .md-chips.md-focused {
      border: 0;
    }
  }
  .form__disabled & {
    .custom-chips.md-koTheme-theme .md-chips.md-focused {
      border: none;
      cursor: not-allowed;
    }
  }
}

.form-item-container.captcha {
  .col--50 {
    width: 48% !important;
  }
}

.form-item-container.addSupplierPrototype {
  & > div {
    padding: 0;
  }
  button {
    margin-top: 20px;
  }
  text-angular {
    height: 84px;
  }
  h3 {
    color: #858585;
    margin-bottom: 10px;
    width: 100%;
    font-weight: normal;
    font-size: 18px;
  }
}


.form-item-upload {
  &__image {
    max-height: none;
    ko-thumbnail {
      width: 100%;
      float: left;
      img {
        width: 100%;
      }
    }
  }
  &__zoom-thumb {
    z-index: 5;
    position: absolute;
    // top: 0;
    left: 23%;
    width: 75%;
    img {
      width: 100%;
    }

    &.last-zoom-thumb {
      bottom: 0;
    }
  }
}

.form-item-container.addFreezer {
  margin-bottom: 20px;

  .col--50 {
    padding: 0 0 0 20px;
  }
  .col--75,
  .col--80 {
    float: left;
  }
  .col--25,
  .col--15 {
    margin-top: 20px;
    padding: 0;
    float: left;
  }

  .legend {
    margin-top: 26px;
    > li {
      margin-left: 0;
    }
  }
}

.fo
.form-item-container.datesRenegociation {

}

@media only screen and (max-width: $mobile_break) {
  .form-item-container.addFreezer {
    .col--80 {
      width: 80% !important;
      padding-right: 15px;
    }
    .col--15 {
      width: 15% !important;
    }
  }
}

@media only screen and (min-width: $mobileFirst_break) {
  .form--content {
    padding: 0 80px;
  }

  .form-item-upload__image {
    max-height: 140px;
    ko-thumbnail {
      img {
        width: auto;
      }
    }
  }
  .form-item-container.renegociation {
    > .form-item {
      width: 100%;
      padding: 0;
    }
    .dates {
      .col--25 {
        padding-right: 4px;
      }
      .col--40 {
        padding: 0;
      }
      .col--30 {
        padding-left: 4px;
      }
    }
  }
}

.form-tooltip {
  //parent element should have relative position
  position: absolute;
  right: 2px;
  margin: 7px;

  &__icon {
    cursor: pointer;
  }

  &__arrow {
    position: absolute;
    right: -3px;
    i {
      font-size: 30px;
      color: #2c2c2c;
      transform: rotate(270deg);
      height: auto;
      line-height: 20px;
      z-index: 6;
    }
  }

  &__tips {
    background: #2c2c2c;
    color: #fff;
    height: auto;
    position: absolute;
    right: -105px;
    margin-top: 16px;
    width: 240px;
    padding: 10px 15px;
    font-size: 14px;
    z-index: 5;

    border-radius: 5px;
    box-shadow: 0px -5px 35px 0px rgba(0, 0, 0, 0.27);

    p {
      font-size: 12px;
      margin-bottom: 3px;
    }

    p.form-tooltip__tips--title {
      margin-bottom: 10px;
    }
  }
}

.limited-content {
  border: 1px solid $border_color;
  border-radius: 4px;
  background: #fff;
  max-height: 300px;
  padding: 1em 0.25em 1em 0.5em;
  overflow: auto;

  p, ol {
    margin-bottom: 1em;
    font-size: 14px;
    line-height: 1.2;
  }
  ol {
    margin-left: 1em;

    li {
      line-height: 1.6;
    }
  }
}

ko-form {
  float:left;
  width:100%;
}

@media only screen and (min-width: 990px) {
  .form-item-container.renegociation {
    > .form-item {
      width: 50%;
      padding: 0;
    }
    > .form-item.col--50:last-child {
      padding-left: 15px;
    }
  }
}
