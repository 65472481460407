.optSelectHeader--request {
  @extend .optSelectHeader;
  float: none;
  width: auto;
  max-width: 100%;
}

.request-toolbar {
  width: 100%;
  border: 0;
  padding: 16px;
  background: #eee;

  th {
    text-align: left;
    font-weight: normal;
    padding-bottom: 5px;
    font-size: 12px;
  }

  td {
    text-align: left;
    font-size: 16px;
  }

}

.accordion__table--request {
  thead {
    th {
      padding: 20px 16px;
    }
  }

  tr {
    border-bottom: 1px solid #d4d4d4;
    cursor: pointer;
  }

  th, td {
    text-align: left;
    padding: 20px 16px;

    &.center {
      text-align: center;
    }

    .arrow {
      transition: all .25s;

      &.expanded {
        transform: rotate(90deg);
      }
    }
  }

  .material,
  .franchise {
    border-bottom: 0;

    &.first { border-top: 1px solid #ccc; }
    &.last { border-bottom: 1px solid #ccc; }

    td {
      padding: 16px;
    }
  }

  .material td { background: #fafafa; }
  .franchise td { background: #eee; }
}