.colHighlight {
  background: #f7f7f7;
}
.dataTable {
  .button {
    &--cancel {
      background-color: #f0f0f0;

      &.button--outline.button--font-s.md-button.ng-scope.md-koTheme-theme.md-ink-ripple:not([disabled]):hover {
        background-color: #e0e0e0;
      }
    }

    &--confirm {
      &.md-button.md-koTheme-theme[disabled] {
        background-color: $red;
        color: #fff;
        opacity: .26;
      }
    }
  }

  .incomplete {
    td {
      opacity: .75;

      &:last-child {
        opacity: 1;
      }
    }
  }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child:before {
  top: 12px;
}

.unit-label {
  font-size: 12px;
  color: red;
  width: 100%;
  display: inline-block;
}

/* Change style - more icon - dataTables */

main {

  .material__icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    width: 1em;
    height: 1em;
    line-height: 1em;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    vertical-align: middle;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }


  table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
  table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    @extend .material__icons;
    color:rgba(0, 0, 0, 0.670588);
    background: none;
    content: "";
    box-shadow:none;
    border-radius:0;
  }

  table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
  table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
    @extend .material__icons;
    color:rgba(0, 0, 0, 0.470588);
    background: none;
    content: "";
    box-shadow:none;
    border-radius:0;
  }
}

table.dataTable.display tbody tr.odd > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_2,
table.dataTable.display tbody tr.odd > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_3,
table.dataTable.display tbody tr.even > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even > .sorting_2,
table.dataTable.display tbody tr.even > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: inherit;
}

table.dataTable.display tbody td.warnningCell {
  background: #ff4747;
  color: #fff;
}
