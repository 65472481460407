/**
###################################
## Angular Material Desig CUSTOM ##
###################################
**/

.material-icons {
  vertical-align: middle;
}

/* selector bug fix */
body table.dataTable.display tbody td.colHidden > .md-button,
.md-button {
  min-width: inherit;
  padding: 0 8px;
}

md-menu-content.md-menu-bar-menu.md-dense .md-button {
  display: block;
}

md-menu-item > .md-button md-icon {
  margin-top: -10px;
}

.md-fab {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.md-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $enabled;
  color: #fff;
}

.custom-chips {
  @extend .form-item__input;
  padding: 0;
  border: 0;

  &.md-koTheme-theme {
    min-height: 40px;
    height: auto;
    overflow: hidden;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    box-shadow: none;

    .md-chips {
      padding: 0 15px 5px;
      box-shadow: none;

      &.error {
        @extend .form-item__input.error;
      }

      &.md-focused {
        box-shadow: none;
        @extend .form-item__input:focus;
      }
    }

    &.disabled {
      border: 0 !important;
    }

    &[readonly=true] {
      @extend .disabled-field;
      padding-bottom: 6px;
    }

  }

  .md-chip {
    height: auto;
    line-height: 1;
    padding: 6px 12px;
    font-size: 11px;

    .md-chip-remove-container {
      top: 0;
      height: 100%;
      padding-top: 2px;
    }

    .md-chip-remove {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      vertical-align: text-top;
    }
  }

  .md-chip-input-container {
    width: 100%;
    margin: 0;
    line-height: 1;
    padding: 0 0;

    &:not(:first-child) {
      margin: 0 0;
    }

    md-autocomplete {
      input {
        font-size: 11px;
        height: 40px;
        line-height: 1;
      }
    }
  }
  &[readonly=true] {
    md-chips-wrap {
      cursor: default;
    }
  }
}

.md-virtual-repeat-container {
  &.md-autocomplete-suggestions-container {
    z-index: 99999;
  }
}

.font-medium {
  font-size: 14px;
}

.font-small {
  font-size: 12px;
}

md-radio-button {
  &.inline {
    display: inline-block;
  }

  &.compact {
    margin: 2px 0 2px 8px;
  }
}

md-checkbox,
md-radio-button {
  &.font-m {
    @extend .font-medium
  }
  &.font-s {
    @extend .font-small
  }

  // Vertical version of checkbox
  // Label over square option
  &[vertical] {
    .md-label {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      margin: 0;
      width: 100%;
      line-height: normal;
    }
    .md-container {
      top: auto;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
}
md-radio-button.md-koTheme-theme[disabled] {
  cursor: not-allowed;
  * {
    cursor: not-allowed;
  }

  .md-on {
    background-color: rgba(0,0,0,0.26);
  }
}

md-autocomplete {
  &.md-koTheme-theme {
    background: #fff;

    md-autocomplete-wrap {
      box-shadow: none;
      border: 1px solid #d8d8d8;
      border-radius: 3px;

      .md-chips & {
        border: none;
      }

      button {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -15px;
      }
    }
  }

  input.error {
    border: 1px solid $red;
    border-radius: 3px;
  }
}
md-autocomplete input:not(.md-input) {
  font-size: 11px;
  width: 100%;
  height: 100%;
  line-height: 1;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  background: #fff;
  border-radius: 0 0 4px 4px;
}

.md-autocomplete-suggestions-container.md-koTheme-theme li:hover {
  background: #ededed;
}

%list_items {
  border-bottom: 1px solid #d1d1d1;
  line-height: 38px;
  height: 38px;
  font-size: 12px;

  &:last-child {
    border: none;
  }
}
.md-autocomplete-suggestions li {
  @extend %list_items;
  padding: 0;

  span {
    padding: 0 15px;
  }
  span.highlight {
   padding: 0;
  }

  span.highlight {
    padding: 0;
  }

  .addNewItem {
    background: #ededed url(../img/addNewIcon.png) no-repeat center right;
    padding: 0 15px;
    display: inline-flex;
    width: 100%;

    span {
      width: 68%;
      padding: 0 4px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000;

      .highlight {
        padding: 0;
        color: #000;
      }
    }

    &:before {
      color: #a8a8a8;
      content: attr(data-label)
    }
  }

  &:hover .addNewItem,
  &.selected .addNewItem {
    background: #d8d8d8 url(../img/addNewIcon.png) no-repeat center right;
  }

}

md-select-menu:not(.md-overflow) md-content {
  padding: 0;
}

md-select.md-koTheme-theme[disabled] .md-select-value.md-select-placeholder {
  background-image: none;
  pointer-events: auto;
}

md-select {
  @extend .form-item__input;

  &.md-koTheme-theme .md-select-value,
  &.md-koTheme-theme:not([disabled]):focus .md-select-value {
    border: none;
    padding-top: 0;
  }
  &[disabled] * {
    cursor: not-allowed;
  }

  .md-select-value {
    *:first-child,
    .md-select-icon {
      transform: none;
    }
    .md-select-icon:after { top: 0; }
  }
}
md-option {
  @extend %list_items;

  .md-text {
    font-size: 12px;
  }
}
md-backdrop.md-select-backdrop {
  z-index: 99998;
}

md-select-menu {
  width: 100%;
  md-content {
    & > div {
      position: absolute;
      top: 0;
      height: 20px;
      width: 100%;
    }
  }
}

.md-select-menu-container md-progress-circular {
  transform: scale(0.3) !important;
  margin: 0 auto !important;
  top: -23px;
}

.md-select-menu-container {
  z-index: 99999;
}

.md-select-menu-container.smallList md-content {
  max-height: 114px;
}

md-icon {
  &.md-koTheme-theme {
      &--nProt {
        color: #ff1212;
        font-size: 20px;
        font-weight: bold;
      }

      &--yProt {
        color: #00ff00;
        font-size: 20px;
        font-weight: bold;
      }
  }
}

.md-koTheme-theme .md-datepicker-input-container {
  border: 0;
  width: calc(100% - 82px);
}

.md-koTheme-theme .md-datepicker-input {
  font-size: inherit;
}

md-datepicker.md-koTheme-theme {
  @extend .form-item__input;
  padding-left: 0;
  padding-right: 8px;
}

button.md-datepicker-button.md-icon-button.md-button.md-koTheme-theme.md-ink-ripple {
  height: 40px;
  line-height: 40px;
}

button.md-datepicker-triangle-button.md-icon-button.md-button.md-koTheme-theme {
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 10px;
  right: 0;
}

.md-datepicker-calendar-pane.md-pane-open {
  background: #fff;
}

.md-datepicker-input-mask {
  width: auto;
  height: auto;
}

.md-datepicker-calendar-pane.md-pane-open {
  z-index: 10000;
}

.md-datepicker-expand-triangle {
  border-left-width: 4px;
  border-right-width: 4px;
}

md-tabs {
  md-content.md-padding {
    padding: 16px 10px;
    min-height: 100px;

    &.md-koTheme-theme {
      background: #f0f0f0;
    }
  }
  md-content.md-padding--vertical-only {
    padding-left: 0;
    padding-right: 0;
  }

  &.md-koTheme-theme {
    md-ink-bar {
      color: #ff1212;
      background: #ff1212;
    }

    .md-tab {
      text-transform: none;
      font-size: 11px;
      color: #505050;
      font-weight: bold;

      &.md-active {
        color: #505050;
      }
    }
  }
}

md-select-menu.md-koTheme-theme md-option:focus {
  background: rgb(238,238,238);
}

md-select.md-koTheme-theme[disabled] .md-select-value {
  color: #000;
  background: none;
  margin-bottom: 0;

  .md-select-icon {
    display: none;
  }
}

md-toast {
  z-index: 99999;
}

@media only screen and (min-width: $mobileFirst_break) {
  md-tabs {
    md-content.md-padding {
      padding: 16px 20px;
    }
    md-content.md-padding--vertical-only {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (min-width: 1200px) {
  md-toast {
    max-width: 600px;
  }
}

md-checkbox {
  .md-icon {
    display: inline-block;
  }
  &.md-checked .md-icon:after {
    transform: rotate(45deg);
    position: absolute;
    display: inline-block;
  }
}

/* Hack IE 10 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .md-select-value .md-select-icon:after {
    position: absolute;
    right: 5px;
  }
}

/* Status active and blue - disabled */
md-switch.md-koTheme-theme[disabled] {
  cursor: not-allowed;

  * {
    cursor: not-allowed;
  }
}

.button--confirm.md-koTheme-theme:not([disabled]):hover {
  background: darken($red, 15%);
}
.button--color.md-koTheme-theme:not([disabled]):hover {
  background: darken($red, 15%);
}

.md-checkbox--inline-block {
  display: inline-block;
}

md-datepicker.md-koTheme-theme[disabled=disabled],
md-select[disabled=disabled] {
  @extend .disabled-field;
}

md-datepicker.md-koTheme-theme[disabled=disabled] input {
  @extend .disabled-field;
}

md-select.md-koTheme-theme[disabled] .md-select-value {
  color: #585858;
}

md-tooltip {
  margin: 0 20px;
}

md-tooltip .md-content {
  height: auto;
  line-height: inherit;
  padding: 8px;
  white-space: normal;
}

md-switch.md-checked .md-thumb-container {
  transform: translate(100%, 0);
}
md-switch .md-thumb-container {
  transform: translate(0, 0);
}
