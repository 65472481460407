.massiveLoading {
  width: 100%;
  height: 100%;
  position: fixed;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 501;
  min-height: 65px;
  top: 0;
  left: 0;
  background: #f2f2f2;
  border-bottom: 1px solid #d2d2d2;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__section {
    height: 60px;
    display: flex;
    align-items: center;

    .logo {
      &__navButton {
        width: 30px;

        md-icon {
          font-size: 30px;
        }
      }
      &__homeButton {
        display: flex;
        align-items: center;
        img {
          height: 32px;
          width: 10px;
          margin: 0 6px;
        }
        h1 {
          display: none;
        }
      }
    }
  }
  .buttons {
    display: none;
  }

  .mdc-badge {
    margin-right: 10px;
    top: 10px;
  }

  .shopcart {
    display: none;
  }
}

.notify {
  display: none;
  min-width: 36px;

  .ico-notify {
    color: inherit;
    margin: 0 2px;
  }

  .mdc-circle-image--small {
    width: 30px;
    height: 30px;
    background: #454545;
    color: #fff;
    border: 2px solid #454545;
    display: inline-block;
    margin: 0;
    border-radius: 50%;

    span {
      font-size: 20px;
      position: relative;
      padding: 0px 3px;
      top: 0px;
      display: inline-block;
      z-index: 10;
    }
  }
}

.shopcart {
  .cart-summary {
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.2s ease-in-out;
  }

  &.is-open {
    .ico-cart {
      border-color: $cart-color-border;

      &.md-koTheme-theme {
        background-color: #fff;
      }
    }

    .cart-summary {
      transform: scaleY(1);
    }
  }
}

.ico-cart {
  overflow: initial;
  //margin-right: 0;
  height: 60px;
  border: 1px solid transparent;
  border-bottom: none;
  margin: 0 2px;
  padding: 0;

  &.md-koTheme-theme:hover {
    background-color: transparent;
  }
}

.sub {
  display: flex;
  position: fixed;
  min-height: 66px;
  padding: 10px 0px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: relative;

  .breadcrumb {
    display: none;
  }

  .bulk-actions {
    padding: 0 0 0 6px;
    position: relative;
    display: flex;
    &--right {
      display: flex;
      button {
        padding-bottom: 0;
        margin-bottom: 0;
        height: 40px;
      }
    }
  }
}

.ico-title {
  font-size: 28px;
  float: left;
  width: 7%;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60px;
}

.sub-title {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.drop-arrow {
  float: right;
  height: 100%;
  .ico-arrow-search {
    color: #000;
  }
}

//grid
.grid {
  float: left;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;

  &--s {
    @extend .grid;
    width: 12%;
  }
  &--m {
    @extend .grid;
    display: none;
  }
  &--l {
    @extend .grid;
    width: 48%;
  }
}

//GENERAL RULES (OUT OF HEADER SCOPE)
.btn {
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-weight: 700;
}

.fullAlign {
  text-align: center;
  display: block;
  position: relative;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.center {
  text-align: center;
}

.sub-title {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 500;

}

.header-transcluded {
  flex: 1;
  align-items: center;
  justify-content: center;

  > ng-include {
    display: flex;
    justify-content: center;
    height: 38px;
  }
}

@media only screen and (min-width: $mobileFirst_break) {
  .header {
    &__section {
    .logo {
        &__homeButton {
          h1 {
            font-size: 14px;
            color: #000;
            display: block;
          }
        }
      }
    }
  }
  .notify {

    .mdc-circle-image--small {
      width: 34px;
      height: 34px;

      span {
        padding: 0px 5px;
      }
    }
  }
  .ico-cart {
    padding: 0 8px;
  }

  .sub {
    .advanced-search {
      position: relative;
    }
    .bulk-actions {
      padding: 0 12px;
    }
    .breadcrumb {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 16px;
      font-weight: 500;
      font-size: 14px;
      min-height: 45px;
      line-height: 45px;

      &__title::before {
        content: '>';
        font-weight: 300;
        margin: 0 5px;
      }
    }
  }
}
