/* Auxiliary mixins */

@mixin clearfix {
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

@mixin columns($count, $gap, $width) {
  -webkit-column-count: $count;
  -moz-column-count: $count;
  column-count: $count;
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
  -webkit-column-width: $width;
  -moz-column-width: $width;
  column-width: $width;
  column-fill: balance;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius-top($radius) {
  -webkit-border-radius: $radius $radius 0 0;
  -moz-border-radius: $radius $radius 0 0;
  -ms-border-radius: $radius $radius 0 0;
  border-radius: $radius $radius 0 0;
}

@mixin border-radius-bottom($radius) {
  -webkit-border-radius: 0 0 $radius $radius;
  -moz-border-radius: 0 0 $radius $radius;
  -ms-border-radius: 0 0 $radius $radius;
  border-radius: 0 0 $radius $radius;
}

@mixin border-radius-left($radius) {
  -webkit-border-radius: $radius 0 0 $radius;
  -moz-border-radius: $radius 0 0 $radius;
  -ms-border-radius: $radius 0 0 $radius;
  border-radius: $radius 0 0 $radius;
}

@mixin border-radius-right($radius) {
  -webkit-border-radius: 0 $radius $radius 0;
  -moz-border-radius: 0 $radius $radius 0;
  -ms-border-radius: 0 $radius $radius 0;
  border-radius: 0 $radius $radius 0;
}

/* Responsive mixins */

$phone: 320px;
$smartphone: 480px;
$tablet-portrait: 600px;
$tablet: 768px;
$desktop: 1024px;

@mixin phone {
  @media (min-width: #{$phone}) {
    @content;
  }
}

@mixin phone-landscape {
  @media (min-width: #{$smartphone}) {
    @content;
  }
}

@mixin smartphone {
  @media (min-width: #{$tablet-portrait}) and (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: #{$tablet-portrait}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

