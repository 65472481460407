.company-register,
.user-register {
  .main-content--center { margin-top: 20px; }
}

.user-register {
  main { margin-top: 0; }
}

.register {
  md-list-item {
    padding: 0;

    &.md-2-line .text--small {
      font-size: 11px;
      color: $disabled;
      font-weight: normal;
    }
  }

  .form-item {
    &.col--30 {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    &.col--50 {
      padding-left: 6px;
      padding-right: 6px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

  }
  &__user {
    .form--content {
      padding-bottom: 20px;
    }
    .form-buttons {
      position: relative;
      display: inline-block;
      height: auto;
      min-height: 40px;
      width: 100%;
      md-progress-circular {
        display: inline-block;
        position: absolute;
        right: -34px;
        top: -30px;
      }
    }
  }
}


@media only screen and (min-width: 400px) {
  .register {
    .form-item.col--50 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .no-padding {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .register {
    .form-item.col--30 {
      width: 30%;
    }
  }
}
