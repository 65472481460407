.sla {

  .main-content {
    &--slasteps {
      min-width: 940px;
    }
  }

  &--macro-step {
    text-transform: uppercase;
  }
  &__label-color {
    width: 14px;
    height: 14px;
    display: block;
    margin: 0 auto;
  }
}

.slasteps {
  md-tabs-wrapper {
   display: none;
  }

  md-list-item.md-2-line > .md-avatar {
    width: 25px;
    height: 25px;
    font-size: 14px;
    background: #ff1212;
    margin-top: 16px;
    margin-right: 8px;
  }


  md-list-item.md-2-line .md-list-item-text h3 {
    font-size: 15px;
    line-height: 26px;
    font-weight: bold;
    width: 490px;
  }

  md-list-item.md-2-line .md-list-item-text p.text--small {
    font-size: 11px;
    font-weight: bold;
  }
}

@media (max-width: 940px) {
  .sla {
    .main-content {
      &--slasteps {
        min-width: 940px;
        margin-right: 15px;
        display: inline-block;
      }
    }
  }
}

@media (max-width: 1024px) {
  .sla {
    .main-content {
      &--slasteps {
        min-width: 940px;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }
}
