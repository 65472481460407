$accordion_border_color: #cdcdcd;

.accordion--table {
  border: 0;
  width: 100%;
  border-collapse: collapse;
  min-width: 940px;

  th, td {
    text-align: left;
    font-weight: normal;
    position: relative;
    font-size: 13px;

    md-checkbox {
      display: inline-block;
    }

    &.center {
      text-align: center;
    }

  }

  tbody {
    border-bottom: 1px solid $accordion_border_color;

    &:last-child {
      border-bottom: 0;
    }

    th, td {
      padding: 4px 16px 0px 16px;
    }


    th {
      text-transform: uppercase;
      padding-left: 45px;
      &:first-child {
        padding-left: 15px;
      }
    }
    td {
     padding: 0px 0px 0px 29px;
      &:first-child {
        padding-left: 40px;
      }
    }
  }


  .arrow {
    &--opened,
    &--closed {
      font-size: inherit;
    }

    &--opened {
      transform: rotate(90deg);
    }
  }
}

.accordion--content {
  border: 1px solid $accordion_border_color;
  border-radius: 4px;
  background: #fff;
  max-height: 200px;
  overflow: auto;
}

.accordion .accordion--content.profile {
  max-height: 280px;
}

body .profile-features {
  min-width: auto;
  max-width: 741px;

  .ckb {
    text-align: left;
    width: 12%;
    padding: 0 0 0 29px;
  }
  thead {
    tr {
      th:first-child {
        width: 52%;
      }
    }
    .ckb {
      text-align: center;
      padding: 0 0px 0 10px;
      width: 12%;
    }

  }
  tbody {
    tr {
      th:first-child {
        width: 100%;
        max-width: 358px;
        padding-top: 8px;
        display: inline-block;
      }
      th {

      }
    }
  }
}

/* ####### ACCORDION INSIDE LIST PAGE ####### */
.accordion {
  &__table {
    border: 0;
    width: 100%;
    min-width: 940px;
    border-collapse: collapse;

    th, td {
      padding: 10px;

      a {
        color: #79A5F6;
        text-decoration: underline;

        &:hover { text-decoration: none; }
      }

      &.sm,
      .sm {
        font-size: 12px;
        font-weight: normal;
      }
    }

    .size {
      &--lg {
        width: 120px;
      }
    }

    .arrow {
      &--opened,
      &--closed {
        color: #333;
        font-size: 13px;
        margin-right: 5px;
        margin-top: -2px;
      }

      &--opened {
        transform: rotate(90deg);
      }
    }

    thead {
      color: #7f7f7f;
      font-size: 13px;
      font-weight: normal;

      th {
        font-weight: normal;
        padding: 20px 10px;
      }
    }

    tbody {
      color: #505050;
      font-size: 14px;
      font-weight: 500;

      .thead {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        md-checkbox {
          display: inline-block;
        }
      }

      .tbody {
        height: 51px;
        background: #fafafa;

        tr:first-child {
          border-top: 1px solid #ddd;
        }
      }
      .tbody--lvl1 {
        background-color: #f2f2f2;

        > td {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .tbody--lvl2 {
        background-color: #f8f8f8;
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      tr {
        &.active {
          border: 2px solid #878787;
        }
      }
    }
  }

  &--freezer {
    @extend .accordion__table;
    min-width: auto;

    tbody .tbody {
      height: 62px;
      border-bottom: 1px solid $accordion_border_color;
      background: #efefef;
    }
    tr > .replan ~ .replan {
      background: $replan-color;
    }

    tr {
      height: 62px;
    }
    td {
      padding: 8px;

      &.active {
        color: #ff0000;
        font-weight: bold;
        input {
          border: 1px solid #ff0000;
          // background: #dadada;
          // border-bottom: 1px solid #c7c7c7;
        }
      }

      &.total {
        text-align: right;
        text-transform: uppercase;
        font-size: 16px;
      }
    }
    .form-item__input {
      height: 34px;
      padding: 0 4px;
      text-align: center;
    }
  }
  &--freezer.lblUnit {
    thead tr:first-child td {
      padding: 20px 8px 6px 8px;
    }
    thead tr:last-child td {
      padding: 2px 8px 4px 8px;
    }
    thead tr {
      height: auto;
    }
  }
}

.fixedColAccordion {
  width: 320px;
  float: left;
  box-shadow: 2px 0 8px rgba(0,0,0,0.2);
  position: relative;

  .accordion--freezer {
    &__name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }
  }
}
.roundedThumb {
  width: 40px;
  height: 40px;
  border: 1px solid $accordion_border_color;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  margin-right: 4px;
}
.tooltipImage {
  &__image {
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
  }
}
/* accordion for lists */
