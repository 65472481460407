md-toast {
  position: fixed;
  display: block;
  padding: 6px 20px;

  &.md-toast-warning-theme {
    background: $warning_color;
    color: #fff;
  }

  &.md-toast-success-theme {
    background: $success_color;
    color: #fff;
  }

  &.md-toast-error-theme {
    background: $error_color;
    color: #fff;
  }
}
