.activation {

  // .main-content {
  //   &--activationsteps {
  //     min-width: 940px;
  //   }
  // }

  &--macro-step {
    text-transform: uppercase;
  }

  &__label-color {
    width: 14px;
    height: 14px;
    display: block;
    margin: 0 auto;
  }
  &__release {
    background: black;
    .form-buttons {
      text-align: center;
      margin: 10px auto;
      width: 100%;
    }
    .button--confirm {
      min-width: 80px;
    }
  }

  .md-datepicker-input-container {
    width: 60%;
  }

  .countries {
    width: 100%;
    .form-item {
      width: 100%;
    }
  }

  .dates {
    .form-item {
      padding-bottom: 7px;
    }
  }

  .dataTable button.status {
    text-transform: initial;
    width: 70px;
  }

  .dataTable button.status[disabled] {
    color: #fff;
    background-color: #FFB7B7;
  }

  .two-points {
    margin-top: 5px;
    position: absolute;
    display: inline-block;
  }

  md-list-item {
    padding: 0;

    &.md-2-line {
      min-height: 26px;

      .md-list-item-text h3 {
        font-size: 15px;
        line-height: 26px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
}

.start-time {
  min-width: 55%;
  .form-item {
    background: #FFF;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    box-shadow: none;
    height: 41px;

    &.error {
      border-color: #df3f17;
    }
  }
  .select-start-time {
    width: 47%;
    display: inline-block;
    border: 0 none;
  }
  &--auto {
    min-width: auto;
  }

  &--small {
    .select-start-time {
      width: auto;
      padding:0 6px 0 14px;
      .md-select-value {
        min-width: auto;
      }
      .md-select-icon {
        width: 15px;
      }

    }
  }
}
// @media (max-width: 940px) {
//   .activation {
//     .main-content {
//       &--activationsteps {
//         min-width: 940px;
//         margin-right: 15px;
//         display: inline-block;
//       }
//     }
//   }
// }
//
// @media (max-width: 1024px) {
//   .activation {
//     .main-content {
//       &--activationsteps {
//         min-width: 940px;
//         margin-right: 10px;
//         display: inline-block;
//       }
//     }
//   }
// }
