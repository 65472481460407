.quotation {
  .last-send-date {
    position: absolute;
    width: 250px;
  }
}

.quotationPg {
  padding-top: 6px;
  label {
    float: left;
    width: 30px;
    margin-top: 4px;
  }

  a {
    font-size: 14px;
    float: left;
  }
}
