@keyframes imageSwapTransitionIn {
  0% {
    opacity: 0;
    transform: scale(1.1);
    border-color: transparent;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.catalog-material {
  display: flex;
  align-items: flex-start;
}

.catalog-material_thumbnails {
  margin-right: $catalog-gutter-extra;
  display: flex;
  flex: 1 0 352px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  > * {
    width: calc(25% - #{$catalog-gutter-half});
    height: 45px;
    background-color: #fff;
    margin-bottom: $catalog-gutter;
    border-radius: 8px;
    border: 3px solid transparent;
    cursor: pointer;
    order: 1;
    transition: opacity 0.3s ease-in,
    border 0.3s ease-in-out;

    &:not(.is-main):hover {
      border-color: $red;
    }
    &.is-main {
      width: 100%;
      height: 193px;
      margin-bottom: $catalog-gutter-double;
      border: none;
      cursor: default;
      order: 0;
      animation: imageSwapTransitionIn 0.9s;
    }
  }

  .koZoomImage-original-holder {
    height: 193px;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
  }

  .koZoomImage,
  .koZoomImage-original {
    width: 100%;
    height: 193px;
  }
  .koZoomImage-original {
    border-radius: 8px;
  }
  .koZoomImage-zoom {
    left: 370px;
    z-index: 99;
  }
}

.catalog-material_card-list {
  margin-right: $catalog-gutter-extra;
  display: flex;
  flex: 1 0 352px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  > .card {
    width: calc(33% - #{$catalog-gutter});
    background-color: #fff;
    border-radius: 8px;
    border: 3px solid transparent;
    cursor: pointer;
    order: 0;
    transition: opacity 0.3s ease-in,
    border 0.3s ease-in-out;
    margin: 0 $catalog-gutter-half $catalog-gutter;

    &:hover,
    &.selected {
      border-color: $red;
    }

    > p {
      font-size: 12px;
      margin: 2px 5px;
      word-break: break-all;
    }

    > img {
      height: 58px;
      background-color: #fff;
      margin-bottom: $catalog-gutter;
      border-radius: 8px;
      border: 3px solid transparent;
      cursor: pointer;
      order: 0;
      transition: opacity 0.3s ease-in,
      border 0.3s ease-in-out;
    }
  }

}

.catalog-material_info {
  width: calc(100% - 352px - #{$catalog-gutter-double} - #{$catalog-gutter});

  &.kit {
    min-width: 50%;
  }

  &.border--bottom {
    padding-bottom: $catalog-gutter-double;
    border-bottom: 1px solid $color-gray;
  }

  &.fixed-min-height {
    min-height: 193px;
  }

  > * {
    word-break: break-all;
  }
}

.catalog-material_name {
  margin-bottom: $catalog-gutter;
  line-height: 1.4;
}

.catalog-info_text {
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: $catalog-gutter-half;
  font-weight: bold;
}

.catalog-info_key {
  font-weight: normal;
}

.catalog-info_text--spaced {
  @extend .catalog-info_text;

  margin-top: $catalog-gutter;
  margin-bottom: $catalog-gutter-double;
}

.catalog-material-prices {
  margin-bottom: $catalog-gutter;

  thead {
    font-size: 14px;
    font-weight: bold;
  }
}

.catalog-material-prices_legend {
  font-size: 14px;
  font-style: italic;
}

.c3m-kaarousel.catalog-detail-related-items {
  margin-top: 60px;
}

@media only screen and (max-width: $mobile_break) {
  .catalog-material {
    flex-direction: column;
    align-items: center;
  }
  .catalog-material_thumbnails {
    margin-right: 0;
    flex: 1 0 290px;
  }
  .catalog-material_info {
    width: 100%;
  }

  .catalog-material_thumbnails {
    .koZoomImage-zoom,
    .mark {
      display: none !important;
    }
  }
}
