.title {
  font-weight: bold;
  color: #2a2a2a;
  margin-bottom: 20px;
  
}

.headerContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;

  .title {
    margin-bottom: 0;
  }

  &__title {
    @extend .title;

    &--small {
      @extend .title;
      font-size: 14px;
      line-height: 16px;
    }

    &--medium {
      @extend .title;
      font-size: 18px;
      line-height: 20px;
    }

    &--large {
      @extend .title;
      font-size: 24px;
      line-height: 26px;
    }
  }
}

.headerText {
  color: #3f3f3f;
  font-size: 13px;
  width: 100%;

  &--spaced {
    margin-top: 30px;
  }

  p {
    padding: 5px 0;
    font-weight: normal;
  }

  label {
    font-weight: bold;
    margin-right: 10px;
  }

  &--small {
    font-size: 14px;
    line-height: 16px;
  }
  &--medium {
    font-size: 18px;
    line-height: 20px;
  }
  &--large {
    font-size: 24px;
    line-height: 26px;
  }
}

@media only screen and (min-width: $mobileFirst_break) {
  .headerContent {
    padding: 20px;
  }
  .headerText {
    padding: 10px 0px 10px;
  }
}
