.buttons {
  &--right {
    @extend .buttons;
    text-align: right;
  }
}

.button {
  @extend .full-word;
  display: block;
  margin: 0 auto 4px;

  &-primary {
    @extend .button;
    background: $red;
    color: #fff;
  }

  &-removeForecast {
    color: $red;
    margin: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &--outline {
    border: 1px solid #cdcdcd;
    text-transform: none;
    color: #454545;
  }
  &--opaque {
    background-color: #fff;
  }

  &--icon {
    @extend .button--outline;
    min-width: inherit;
    padding: 0 8px;
    border-radius: 3px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    min-height: 36px;
    line-height: 36px;
    display: inline-block;

    &:hover {
      background-color: #dedede;
    }
  }

  &--color {
    // background-color: #4285f4;
    background-color: $red;
    color: #fff;
  }

  &--font-s {
    font-size: 12px;
  }

  &--full {
    @extend .button;
    width: 100%;
  }

  &--small {
    @extend .button;
    width: 70px;
  }

  &--medium {
    @extend .button;
    width: 200px;
  }

  &--large {
    @extend .button;
    width: 250px;
  }

  &--extra-large {
    @extend .button;
    width: 300px;
  }

  &--inline {
    display: inline-block;
    margin: 0 4px 4px;
  }

  &--left {
    margin: 0 0 4px;
  }

  &--right {
    float: right;
    clear: both;
  }

  &--vertical-line {
    display: inline-block;
    border-left: thick solid #cdcdcd;
    border-width: 1px;
    height: 35px;
    margin: 0px 8px;
    vertical-align: top;
  }

  &--add,
  &--remove {
    @extend .button;
    @extend .button--inline;
    @extend .button--outline;
    margin: 0;
    border: 1px solid #d3d3d3;
    font-size: 20px;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    float: left;
    width: 50%;
  }

  &--add {
    border: 1px solid #d83a3a;
    background-color: $red;
    color: #fff;
    border-radius: 0 4px 4px 0;

    &:hover {
      background-color: $red !important;
      color: #fff !important;
    }

    &[disabled=disabled] {
      background: #dcdcdc !important;
      border: 1px solid #d3d3d3 !important;
      color: #fff !important;
    }
  }

  &--remove {
    // width: 38px;
    color: #222;
    background: #f7f7f7;
    border-radius: 4px 0 0 4px;
    border-right: 0;

    &:hover {
      background: #f7f7f7 !important;
    }
  }

  &--cancel {
    @extend .button;
    @extend .button--inline;
    @extend .button--outline;
    @extend .button--font-s;
    text-transform: uppercase;
  }

  &--confirm {
    @extend .button;
    @extend .button--inline;
    @extend .button--outline;
    @extend .button--font-s;
    text-transform: uppercase;
    background-color: $red;
    color: #fff;

    &:hover {
      background: inherit;
    }
  }

  &--back-pagination-datatable {
    @extend .button;
    float: left;
    margin: 1px 12px 1px 0;
  }

  &--disalable {
    &[disabled=disabled] {
      opacity: 0.35;
    }
  }

  &--cascade {
    background: url(/svg/ico-btn-cascata.svg) no-repeat center center;
    width: 38px;
  }
  &--straight-cascade {
    background: url(/svg/ico-btn-straight-cascata.svg) no-repeat center center;
    width: 38px;
  }

  &--catalog--add {
    $shopping_cart_red: #ff260b;
    $shopping_cart_normal: #fff;
    $default_text_color: #505050;
    $negative_text_color: #fff;
    @extend .button;
    /**
     * Needed to be an anchor because of the icon
     **/
    @include border-radius(6px);
    padding: 0 8px 0 0;
    width: 122px;
    min-height: 32px;
    background: $shopping_cart_normal;
    color: $default_text_color;
    font-size: 14px;
    text-transform: uppercase;
    text-align: right;

    &:hover {
      background: $shopping_cart_red !important;
      color: $negative_text_color !important;
    }

    .material-icons {
      @include border-radius-left(6px);
      margin-right: 5px;
      padding: 0 3px 0 5px;
      width: 33px;
      display: inline-block;
      background: $shopping_cart_red;
      color: $negative_text_color;
      font-size: 22px;
      text-transform: lowercase;
      line-height: 32px;
    }

    &[disabled="disabled"] {
      background: $shopping_cart_normal !important;
      color: rgba(0, 0, 0, 0.26);
      .material-icons {
        background: $shopping_cart_normal;
        color: #d3d3d3;
      }
      &:hover {
        background: $shopping_cart_normal !important;
        color: rgba(0, 0, 0, 0.26) !important;
      }
    }
  }
}

.action-button--icon {
  width: 100px;
  .action-button__wrapper {
    display:flex;
    justify-content:space-around;
    align-items:center;
  }
  .action-button--is-off {
    opacity: 0.6;
  }

  .action-button__icon {
    width: 30px;
  }
  .action-button__label {
    flex: 1;
    text-align: center;
  }
}

.md-button.md-koTheme-theme[disabled].btOrderAccepted {
  color: #00c100;
}

.md-button.md-koTheme-theme[disabled].btOrderRejected {
  color: #ff0000;
}

.md-button.md-button--progress {
  min-width: 120px;

  md-progress-circular {
    transform: scale(0.15) !important;
    height: 0;
    width: 100%;
    top: -25px;
    right: -40px;
  }
}

.red-link {
  font-weight: bold;
  color: #ff0000;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

@media only screen and (max-width: 640px) {
  .button--insert,
  .button--update {
    display: none;
  }
}
