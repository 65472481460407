.demos {
  padding: 20px;

  h1, h2 { margin-bottom: 10px; }
  ul { margin-left: 40px; }
  li { list-style: initial; }
  p { margin: 7px 0; }

  .highlight { color: red; }
  .back {
    display: block;
    margin-bottom: 30px;
  }
}
