// ---------------
// GENERIC UTILITY CLASSES
// ---------------

// --------------------
// Hides element content.
// Usefull for css sprite technique.
// Example: <h1 class="hidden it-logo">Coca-Cola - Abra a felicidade</h1>
.hidden {
  text-indent: -9999px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

// --------------------
// Prevent break word
// Example: <p>FAÇA SEU LOGIN OU <span class="full-word">CADASTRE-SE</span></p>
.full-word {
  white-space: nowrap;
}

// --------------------
// Add ellipsis (...) when text is larger than it´s container
// Example: <p class="truncate">Olá, Pedro de Alcântara Francisco Antônio João Carlos Xavier de Paula Miguel</p>
.truncate {
  @extend .full-word;
  text-overflow: ellipsis;
  overflow: hidden;
}

.main-col {
  @extend .truncate;
  max-width: 200px;
  display: inline-block;
  vertical-align: middle;
}

.hint {
  font-size: 10px;
  line-height: 12px;
  margin: 5px 0;
  display: block;
}

.uppercase {
  text-transform: uppercase;
}

.large-main-col {
  @extend .main-col;
  max-width: 350px;
}

.disabled-field {
  color: #585858;
  background: #f5f5f5;
  cursor: not-allowed;
}

// SCCC-3996: fixed calendar's display setting the width according bellow.
// This solution was applied to avoid update the calendar component that could cause impacts.
.md-calendar-scroll-container {
  width: 308px;
}