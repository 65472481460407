$menu-catalog-gutter: 10px;
$menu-catalog-gutter-desk: 20px;

.menu-catalog {
  //position: absolute;
  //left: 50%;
  //transform: translateX(-50%);
  //height: 100%;
  display: flex;
  align-items: flex-end;

  > .menu-catalog_item {
    border-bottom: 2px solid transparent;
    //border-bottom: 4px solid transparent;
    transition: all 0.3s ease-out;

    .md-button {
      text-transform: capitalize;
      padding-left: $menu-catalog-gutter;
      padding-right: $menu-catalog-gutter;
      transition: all 0.3s linear;
      margin: 6px 4px;
    }

    &.active {
      border-bottom-color: $red;
      color: $red;

      .md-button {
        //transform: scale(1.2);
      }
    }
  }
}
