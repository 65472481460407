@-webkit-keyframes ngdialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ngdialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes ngdialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes ngdialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.ngdialog-open {
  html &,
  body & {
    overflow: initial !important;
  }

  html & {
    overflow-y: hidden !important;
  }

  &.ngdialog-open--hidden {
    html &,
    body & {
      overflow: hidden;
    }
  }
}

.ngdialog.ngdialog-theme-default,
.ngdialog.ngdialog-theme-default .ngdialog-overlay {
  position: fixed;
}

.ngdialog.ngdialog-theme-default .ngdialog-overlay {
  background: transparent;
  right: 15px;
}

.ngdialog.ngdialog-theme-default {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4% 0;
  background: rgba(0, 0, 0, 0.7);
}
.ngdialog.dialog--clean {
  .ngdialog-content {
    background-color: #fff;
  }
}

.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  color: #fff;
  top: 8px;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-default .ngdialog-close:active:before {
  color: #fff;
}
.ngdialog.ngdialog-theme-default.small .ngdialog-content {
  width: 400px;
}

.ngdialog.ngdialog-theme-default.ngdialog-closing .ngdialog-content {
  -webkit-animation: ngdialog-flyout .5s;
  animation: ngdialog-flyout .5s;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  margin: 0;
  -webkit-backface-visibility: visible;
  padding: 86px 20px 40px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  width: 600px;
}

.ngdialog.ngdialog-theme-default .ngdialog-content--fixed {
  align-self: flex-start;
}

.ngdialog-content form {
  width: 100%;
  float: left;
}

.ngdialog.ngdialog-theme-default.medium .ngdialog-content {
  width: 735px;
}

.ngdialog.ngdialog-theme-default.large .ngdialog-content {
  width: 800px;
}

.ngdialog.ngdialog-theme-default.tabs .ngdialog-content {
  padding: 46px 0 24px;

  .md-tab.md-disabled {
    opacity: 1;
  }
}

.ngdialog.ngdialog-theme-default.tabs.issue .ngdialog-content {
  padding: 46px 0 0px;

  .md-tab.md-disabled {
    opacity: 1;
  }
}

.md-dialog-container {
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: $mobile_break) {
  .ngdialog.ngdialog-theme-default .ngdialog-content {
    padding: 66px 10px 40px;
  }

  .ngdialog.ngdialog-theme-default {
    padding: 0;
  }
  .dialog__header {
    border-radius: 0;
  }
  .ngdialog.ngdialog-theme-default .ngdialog-content {
    border-radius: 0;
  }
}
