* {
   box-sizing: border-box;
   outline: none;
   margin: 0;
   padding: 0;
   list-style: none;
   text-decoration: none;

   // &::selection {
   //     background-color: #FF0000;
   //     color: #FFFFFF;
   // }
}

.hiddenScroll {
  overflow: hidden;
}

html, body {
  height: auto;
  min-height: 100vh;
  background: #fff;

}

body {
  // position: static !important;
  position: static;
}

//timemanager
.timeFrozen {
  width: 300px;
  height: 32px;
  background: #ff2828;
  position: fixed;
  z-index: 9999;
  transform: rotate(-38deg) translate(-51px,-16px);
  color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0, 0.4);
  display: flex;
  padding: 3px 0 0 32px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #e00000;
  visibility: hidden;
  p {
    margin: 4px 0 0 8px;
  }
  img {
    width: 25px;
    height: 24px;
  }
}
.timeFrozen.logged {
  visibility: visible;;
}

main {
  display: inline-block;
  width: 100%;
  height: auto;
  margin-top: 65px;
}

img {
  max-width: 100%;
}

a {
  cursor: pointer;
}

p {
  line-height: 16px;
}

input,
input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance:    none;
  border-radius: 0;
}

.error {
  border: 1px solid #f00;
}

// .noDesk {
//   display: block!important;
// }

.main-content {
  margin: 68px 0 10px 0;
  background: #fff;
  padding: 10px 0;

  &--tabbed {
    padding-bottom: 0;

    md-tab-content {
      border-radius: 7px;

      md-content {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        > * {
          width: 99%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    md-tabs md-content.md-padding.md-koTheme-theme {
      background-color: #fff;
      padding-top: 40px;
    }
  }

  &--wide {
    // Important was used because of responsive overriding rules.
    // Remove it after css refactoring
    margin-bottom: 20px !important;

    thead > * {
      min-width: 60px;
    }

    .headerContent {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }

  &--center {
    margin: 0 auto;
  }

  &--external {
    margin: 1% auto;
    max-width: 700px;
    width: 100%;
    padding: 0 10px;
  }

}

.form-page {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  display: table;
}

@media only screen and (max-width: $mobile_break) {
  .noMobile {
    display: none!important;
  }
}

@media only screen and (min-width: $mobileFirst_break) {
  .noDesk {
    display: none !important;
  }

  html, body {
    background: $primary_bg;
  }

  .main-content {
    margin: 85px 16px;
    border-radius: 7px;
  }
}

ko-header-views,
header-views {
  display: block;
  position: fixed;
  top: 65px; // header height
  z-index: 500;
  width: 100%;
}

nav-views {
  display: block;
}

md-tooltip {
  z-index: 15000;
}
