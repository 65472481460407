.scrollx {
  width: 100%;
  height: 20px;
  overflow-x: scroll;
  overflow-y:hidden;
  position: absolute;
  z-index: 99999;
  //visibility: hidden;
  display: none;

  div {
    height: 20px;
  }
}

.dataTables_wrapper .ui-toolbar,
.dataTables_wrapper .dataTables_filter {
  display: none;
}

table.dataTable {
  color: #505050;
  font-size: 14px;
  font-weight: 500;
}

table.dataTable md-checkbox {
  display: inline-block;
}

table.dataTable thead .sorting  {
  background-image: none;
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable tbody.fixedHeader .sorting_asc,
table.dataTable tbody.fixedHeader .sorting_desc {
  //white-space: initial;
  cursor: pointer;
  white-space: nowrap;
  background-image: none;
}

table.dataTable thead th div.DataTables_sort_wrapper {
  display: inline-block;
  padding: 0 17px 0 0;
}

table.dataTable thead .sorting div.DataTables_sort_wrapper {
  background: url(/img/sort_both.png) no-repeat center right;
}

table.dataTable thead .sorting_asc div.DataTables_sort_wrapper {
  background: url(/img/sort_asc.png) no-repeat center right;
}

table.dataTable thead .sorting_desc div.DataTables_sort_wrapper {
  background: url(/img/sort_desc.png) no-repeat center right;
}

table.dataTable.no-footer {
  background: #fff;
  border: none;
}

table.dataTable tbody td {
  padding: 0 10px;
  box-sizing: border-box;
}

table.dataTable thead th, table.dataTable thead td, table.dataTable tbody.fixedHeader td {
  padding: 10px 12px;
  font-weight: normal;
  color: #7f7f7f;
  font-size: 13px;
  box-sizing: border-box;
}

table.dataTable.display tbody tr {
  height: 51px;
  position: relative;
}

table.dataTable.display tbody tr.rowSelected {
  background: #f6f6f6;
}

table.dataTable.display tbody tr .hiddenItens {
  position: absolute;
  z-index: 2;
  background: red;
  width: 100%;
  left: 0;
  top: 0;
}

table.dataTable.display tbody tr.odd {
  background: #fff;
}

table.dataTable.display tbody tr.even>.sorting_1, table.dataTable.order-column.stripe tbody tr.even>.sorting_1,
table.dataTable.display tbody tr.odd>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
  background: #fff;
}

table.dataTable thead th, table.dataTable thead td {
  border-bottom: 1px solid #dcdcdc;
}

table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting:after,
table.dataTable tbody.fixedHeader .sorting:after,
table.dataTable tbody.fixedHeader .sorting_asc:after,
table.dataTable tbody.fixedHeader .sorting_desc:after {
  opacity: 0;
  content: "";
}

table.dataTable md-switch .md-container {
  margin: 0 auto;
}

table.dataTable md-checkbox .md-container {
  left: 11%;
}

table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
  background-color: #fff;
}

table.dataTable.hover tbody tr.rowSelected:hover,
table.dataTable.display tbody tr.rowSelected:hover {
  background-color: #f6f6f6;
}

.naResultGrid {
  color: #7f7f7f;
  font-weight: normal;
}

.linkInGrid {
  color: #4285f4;
  text-decoration: underline;
}

.folderInGrid {
  margin-right: 20px;
}

table.dataTable>tbody>tr.child {
  ul {
    width: 100%;
    padding-top: 10px;
  }

  li {
    display: table;
    width: 100%;
  }

  span.dtr-title,
  span.dtr-data {
    display: table-cell;
    padding: 0;
    vertical-align: middle;
  }

  span.dtr-title {
    //width: 30%;
    float: left;
  }

  span.dtr-data {
    //width: 70%;
    float: left;
  }

}


table.dataTable.display tbody td.colHidden {
  border: none;
  padding: 0;

  > * {
    padding: 0;
    margin: 0;
  }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.child {
  padding-left: 14px;
}

table.dataTable {
  td.dataTables_empty {
    text-align: left;
    padding: 30px;
  }
}

table .material-icons {
  font-size: 20px;
}

table.dataTable .grid-star {
  color: #ffc600;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child,
table.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child {
  padding-left: 10px;
  min-width: 80px;
}

table.dataTable tbody td a.md-button {
  display: inline-table;
  height: auto;
  min-height: initial;
  line-height: 1em;
  padding: 2px 0;
  margin: 0;
  color: #79A5F6;
  text-decoration: underline !important;
  text-transform: none;

  &.button--outline {
    padding: 10px 8px;
    margin: 6px 8px;
    color: #454545;
    text-decoration: none !important;

    &:hover {
      background-color: rgba(158,158,158,0.2) !important;
    }
  }

  &[disabled] {
    text-decoration: none !important;
  }

  &:hover {
    background-color: transparent !important;
  }
}

table.dataTable tbody td a:not(.md-button) {
  color: #79A5F6;
  text-decoration: underline !important;
}

.span_status {
  width: 1px;
  overflow: hidden;
  text-indent: -999px;
  height: 1px;
  display: block;
}

table.dataTable td.koHiddenCol {
  display: none;
}

table.dataTable.collapsed > thead > tr > td:first-child,
table.dataTable.collapsed > thead > tr > th:first-child {
  padding-left: 36px;
}

table.dataTable.collapsed > tbody > tr > td:first-child:before,
table.dataTable.collapsed > tbody > tr > th:first-child:before {
  color: rgba(0, 0, 0, 0.670588);
  background: none;
  content: "\E5D4";
  box-shadow: none;
  border-radius: 0;
  float: left;
}

table.dataTable.collapsed > tbody > tr > td:first-child:before,
table.dataTable.collapsed > tbody > tr > th:first-child:before,
table.dataTable.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.collapsed > tbody > tr.parent > th:first-child:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  width: 1em;
  height: 1em;
  line-height: 1em;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
}

table.dataTable.collapsed > tbody > tr > td:first-child,
table.dataTable.collapsed > tbody > tr > th:first-child {
  padding-left: 10px;
  min-width: 80px;
}

table.dataTable.collapsed>tbody>tr>td:first-child,
table.dataTable.collapsed>tbody>tr>th:first-child {
  position: relative;

  &:before {
    cursor: pointer;
  }
}

.grid {
  width: 500px;
  height: 250px;
}

.koGridHolder {
  width: 100%;
  height: 100%;
  display: inline-block;
  left: 0;
  position: relative;

  md-checkbox {
    margin: 0;
    line-height: 14px;
    min-height: 15px;
  }
}
.koGridHolder--wide {
  overflow-x: auto;
  padding-bottom: 10px;
  position: relative;
  left: 0;

  thead > td {
    min-width: 60px;
    width: auto;
  }
}
table.dataTable {
  thead td {
    min-width: 60px;
  }
  tbody tr {
    &.u-disabled {
      color: $enabled;
    }
  }
  tbody td {
    padding-top: 0;
    padding-bottom: 0;

    &.u-spaced {
      padding-top: $gutter-half;
      padding-bottom: $gutter-half;
    }
  }
}

.koGridHolder table.dataTable.collapsed > tbody > tr > td:first-child.child:before {
  content: '';
}

.koGridHolder table.dataTable.collapsed > tbody > tr > td:first-child {
  .koHiddenCol {
    display: block;
    border-top: none;
  }

  .grid_backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 30px;
    background: #fff;
    opacity: 0;
    z-index: 400;
  }

  ul {
    position: absolute;
    left: 24px;
    top: 50%;
    margin-top: 18px;
    background: #fff;
    z-index: 2;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
    padding: 10px;
    display: table;
    border: 1px solid #f1f1f1;
    z-index: 500;

    li {
      //width: 100%;
      //float: left;
      display: table-row;
      text-align: left;

      span {
        //float: left;
        //width: auto;
        padding: 6px 0;
        display: table-cell;

        md-switch {
          width: 40px;
          margin: 0;
        }
      }

      span.dtr-title,
      span.dtr-data {
        border-bottom: 1px solid #f1f1f1;
      }
    }

    li:last-child span {
      border-bottom: none;
    }
  }
}

.koGridHolder table.dataTable thead .sorting {
  background-image: none;
  // white-space: initial;
  white-space: nowrap;
}

.koGridHolder table.dataTable thead .sorting_asc {
  background-image: none;
}

.koGridHolder table.dataTable thead .sorting_desc {
  background-image: none;
}

.koGridHolder table.dataTable thead .sorting_desc:after,
.koGridHolder table.dataTable thead .sorting_asc:after,
.koGridHolder table.dataTable thead .sorting:after,
table.dataTable tbody.fixedHeader .sorting:after,
table.dataTable tbody.fixedHeader .sorting_asc:after,
table.dataTable tbody.fixedHeader .sorting_desc:after {
  opacity: 1;
  content: "";
  width: 14px;
  height: 11px;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 0;
}

.koGridHolder table.dataTable thead .sorting:after,
.koGridHolder table.dataTable tbody.fixedHeader .sorting:after {
  background: url(/img/sort_both.png) no-repeat center right;
}

.koGridHolder table.dataTable thead .sorting_asc:after,
.koGridHolder table.dataTable tbody.fixedHeader .sorting_asc:after {
  background: url(/img/sort_asc.png) no-repeat center right;
}

.koGridHolder table.dataTable thead .sorting_desc:after,
.koGridHolder table.dataTable tbody.fixedHeader .sorting_desc:after {
  background: url(/img/sort_desc.png) no-repeat center right;
}

.koGridHolder table.dataTable thead .sorting_desc,
.koGridHolder table.dataTable thead .sorting,
.koGridHolder table.dataTable thead .sorting_asc,
.koGridHolder table.dataTable tbody.fixedHeader .sorting_desc,
.koGridHolder table.dataTable tbody.fixedHeader .sorting,
.koGridHolder table.dataTable tbody.fixedHeader .sorting_asc {
  position: relative;
  cursor: pointer;
}


// @media (min-width: 768px) {
//   .koGridHolder {
//     left: 16px;
//   }
// }

table.dataTable.collapsed>.fixedHeader>tr>td:first-child {
  padding-left: 36px;
}

.fixedHeader {
  display: none;
  position: absolute;
  left: 0;
  z-index: 99;
  // top: 47px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.3);
  background: #fff;
  font-weight: normal;
  color: #7f7f7f;
  font-size: 13px;

  tr td:first-child:before {
    display: none;
  }
}
