.cg-busy-backdrop {
  background-color: transparent;
}
.ko-loading {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  position: fixed;
  z-index: 9999;

  &__overlay {
    background: radial-gradient(#fff 0%, rgba(#fff, 0) 80%);
    opacity: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__loader {
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    z-index: 2;
    position: absolute;
  }
}
